import React from "react";
import {Routes,Route,Navigate} from "react-router-dom";
import {useAnimate} from "react-simple-animate";

import "../components/styles/adminDashboard.css";

import Dashboard from "../components/dashboard/Dashboard";
import Navbar from "../components/Navbar";
import FeesStructureTable from "../components/FeesStuctureTable";
import AddNewStudent from "../components/AddNewStudent";
import AdminTable from "../components/AdminTable";
import ActivityTable from "../components/ActivityTable";
import Sidebar from "../components/Sidebar";
import StudentAccountTable from '../components/accounts/StudentAccountTable'
import StudentAccountTableDeactiveStudent from '../components/accounts/StudentAccountTableDeactiveStudent'
import {ToastContainer} from "react-toastify";
import SidebarLinks from "./SidebarLinks";
import HeaderLink from "./HeaderLink";
import StudentProfileDA from "../components/accounts/StudentProfileDA";
import StudentProfile from "../components/accounts/StudentProfile";
import UploadDocumentFrom from "../components/accounts/UploadDocumentFrom";
import FeesRecipt from "../components/accounts/FeesRecipt";
import PendingScholarshipTable from "../components/accounts/PendingScholarshipTable";
//Test
import StudentTable from '../components/StudentsTable'
import FeesReceiptTable from "../components/accounts/FeesReceiptTable";
import StudentTableFirst from "../components/student/StudentTableFirst";
import UpdatePersonalDetialOfStudent from "../components/accounts/UpdatePersonalDetialOfStudent";
import DeleteReceiptTable from "../components/DeleteReceiptTable";
import SelfAppliedStudentTable from "../components/SelfAppliedStudentTable";
import StudentsTableAppliedAndSelfApplied from "../components/StudentsTableAppliedAndSelfApplied";
import RegisteredStudent from "../components/accounts/RegisteredStudent";
import UpdateSelfAppliedStudentDetail from "../components/UpdateSelfAppliedStudentDetail";
import Attendance from "../components/Attendance"
import PromoteStudents from "../components/PromoteStudents";
import AluminiAccountTable from "../components/alumini/AluminiAccountTable";
import BusPass from "../components/accounts/busPass";
import StudentActivities from "../components/accounts/StudentActivities";
import BusFeesDetailsTable from "../components/accounts/BusFeesDetailsTable";
import BusFeesStudentTable from "../components/accounts/BusFeesStudentTable";
import {CertificatesAndCardRoutes} from "../components/cards-certificate/CertificatesAndCardRoutes";
import {StudentCardAndCertificateAsZip} from "../components/cards-certificate/certificate_card_zip/StudentCardAndCertificateAsZip";
import {Certificate} from "../components/cards-certificate/certificate/CertificateForm/Certificate";
import BegMegItegIdcard from "../components/cards-certificate/Beg_Meg_Iteg_id_card";
import StaffCards from "../components/cards-certificate/StaffCards";
import {TeachersCard} from "../components/cards-certificate/teacher-card/TeachersCard";
import RegistrationsDashboard from "../components/dashboard/registrations";
import Notification from "../components/Notification"
import DepositAmountTable from "../components/depositAmount/depositAmountTable";

function AdminDashboard() {
  const {play,style,isPlaying} = useAnimate({
    start: {
      width: "281px",
      minWidth: "280px",
    },
    end: {
      width: "95px",
      minWidth: "95px",
    },
  });

  return (
    <div className="main_container_dashboard">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="side_bar_content" style={style}>
        <Sidebar play={play} isPlaying={isPlaying} Options={SidebarLinks} />
      </div>
      <div className="header_table">
        <div className="header_dashboard">
          <Navbar Options={HeaderLink} />
        </div>


        <div className="table_dashboard">
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="registrations-dashboard" element={<RegistrationsDashboard />} />
            <Route path="studenttable" element={<StudentTable />} />
            <Route path="selfappliedstudent" element={<SelfAppliedStudentTable />} />
            <Route path="selfappliedandapliedstudent/:trackName" element={<StudentsTableAppliedAndSelfApplied />} />

            {/* Nested routes  */}
            <Route path="studentaccounttable" element={<StudentAccountTable />} />
            <Route path="deactivestudentaccounttable" element={<StudentAccountTableDeactiveStudent />} />
            {/* Nested routes  */}
            <Route path="admintable" element={<AdminTable />} />
            <Route path="attendance/*" element={<Attendance />} />



            {/* Adding student page   */}
            <Route path="students" element={<StudentTableFirst />} />
            <Route path="registeredStudent" element={<RegisteredStudent />} />


            <Route path="addnewstudent" element={<AddNewStudent />} />
            <Route path="updateselfappliedstudentdetail" element={<UpdateSelfAppliedStudentDetail />} />

            <Route path="feesstructuretable" element={<FeesStructureTable />} />
            {/* // */}
            <Route path="dastudentprofile" element={<StudentProfileDA />} />
            <Route path="studentprofile" element={<StudentProfile />} />
            {/*  */}
            <Route path="dastudentprofile/uploaddocument" element={<UploadDocumentFrom />} />
            <Route path="studentprofile/uploaddocument" element={<UploadDocumentFrom />} />
            {/*  */}
            <Route path="dastudentprofile/feesrecipt" element={<FeesRecipt />} />
            <Route path="studentprofile/feesrecipt" element={<FeesRecipt />} />
            {/*  */}
            <Route path="pendingscholarship/:sessionId/:feesType" element={<PendingScholarshipTable />} />
            <Route path="feesreceiptlist" element={<FeesReceiptTable />} />
            {/*  */}
            <Route path="dastudentprofile/updatepersonaldetial" element={<UpdatePersonalDetialOfStudent />} />
            <Route path="studentprofile/updatepersonaldetial" element={<UpdatePersonalDetialOfStudent />} />
            <Route path="reportedreceipt" element={<DeleteReceiptTable />} />
            <Route path="promote-students" element={<PromoteStudents />} />
            <Route path="notification" element={<Notification />} />
            <Route path="alumini-students" element={<AluminiAccountTable />} />
            {/*  */}
            <Route path="dastudentprofile/bus-pass" element={<BusPass />} />
            <Route path="studentprofile/bus-pass" element={<BusPass />} />
            <Route path="pendingbusfees/:sessionId/:track" element={<BusFeesDetailsTable />} />
            <Route path="pendingbusfeesstudent/:sessionId/:track/:month" element={<BusFeesStudentTable />} />
            {/*  */}
            <Route path="dastudentprofile/activity" element={<StudentActivities />} />
            <Route path="studentprofile/activity" element={<StudentActivities />} />
            <Route path="activitytable" element={<ActivityTable />} />
            <Route path="bankdepositamount" element={<DepositAmountTable />} />
            

            <Route path="cards-certificate" element={<CertificatesAndCardRoutes />} >
              <Route exact path="teacher" element={<StaffCards />} >  </Route>
              <Route exact path="" element={<BegMegItegIdcard />} >  </Route>
              <Route exact path="certificate" element={<Certificate />}>  </Route>
              <Route exact path="student-id-card-certificate-zip" element={<StudentCardAndCertificateAsZip />}  > </Route>
              <Route exact path="teacher-card-zip" element={<TeachersCard />}>  </Route>
            </Route>

            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
