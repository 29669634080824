// export const baseUrl = "https://singaji-central-server.herokuapp.com";
// export const baseUrl = "https://singaji-central-server-master.herokuapp.com";
// export const baseUrl = "http://ssismback-env.eba-wffxcjqm.us-east-1.elasticbeanstalk.com";
// export const baseUrl = "https://backend.ssism.org";
// export const baseUrl = "https://cdp.baelworks.com";
export const baseUrl = "https://api.ssism.org"; 
   // export const baseUrl = "http://182.78.218.74"; 
   
// export const baseUrl = "http://192.168.1.86:3005";
// export const baseUrl = "http://localhost:5000";
// export const baseUrl = "http://localhost:5000";