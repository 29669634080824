import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Numberformat from 'react-number-format';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import * as Yup from "yup";
import { isAccountAdmin } from '../../../helpers/AccountAdmin';
import { isStudentAdmin } from '../../../helpers/StudentAdmin';
import { isSuperAdmin } from '../../../helpers/SuperAdmin';
import { accountAction3 } from '../../../redux/actionDispatcher/superAdmin/studentAccountTableDataDispatcher';
import { default as AllUrl, default as allUrls } from '../../../redux/constants/url';
import Loader from '../../assests/common/Loader';
import '../styles/AddNewStudent.css';


function BusPass({ accountAction3 }) {
    const token = localStorage.getItem("token");
    const navigate = useNavigate()

    const data = localStorage.getItem('userEdit')
    const StudentAccountData = JSON.parse(data)
    // // console.log('StudentAccountData ::',StudentAccountData)
    var StudentName = StudentAccountData.accountInfo.firstName + ' ' + StudentAccountData.accountInfo.lastName
    var StudentClassName = StudentAccountData.accountInfo.branch
    // // console.log((StudentAccountData.pendingFee).length);
    const busPassData = StudentAccountData.busPasses;
    // console.log(busPassData)
    const [loading, setLoading] = useState(false);
    const [chargebleMonth, setChargebleMonth] = useState([]);


    var totalFeesAmount = 0;
    var totalpendingFees = 0;
    var totalReceivedFees = 0;
    var totalSettledFees = 0;


    (StudentAccountData.pendingBusFees).forEach((ele) => {

        totalFeesAmount = totalFeesAmount + parseInt(ele.feesAmount)
        totalReceivedFees = totalReceivedFees + parseInt(ele.receivedFees)
        totalpendingFees = totalpendingFees + parseInt(ele.pendingFees)
        totalSettledFees = totalSettledFees + parseInt(ele.settledFees)

    })


    const getMOnth = () => {

        let MM = new Date().getMonth();
        return (`${(parseInt(MM) >= 9) ? "" : "0"}${MM + 1}`)
    }

    // console.log()
    const initialValues = {
        payBy: 'Cash',
        isSettled: false,
        studentName: `${StudentName}`,
        studentClassYear: StudentAccountData.accountInfo.year + "_" + StudentAccountData.pendingFee.filter((ele) => `${ele.year}` === StudentAccountData.accountInfo.year && ele.isLatest === "true")[0].revisionNumber,
        FatherName: StudentAccountData.accountInfo.fathersName,
        feesAmount: `` + StudentAccountData.accountInfo.busFee,
        recieptdate: `${new Date().getFullYear() + '-' + getMOnth() + '-' + (`${(new Date().getDate() > 9) ? "" : "0"}${new Date().getDate()}`)}`,
        month: '',
        Remark: '',
    }
    function getDifferenceInDays(date) {
        // // console.log(date2);
        let X = new Date(date);
        let Y = new Date();
        // console.log(Y);
        const dateY = `${Y.getMonth()}/${Y.getDate()}/${Y.getFullYear()}`;
        const dateX = X.getMonth() + '/' + X.getDate() + '/' + X.getFullYear();
        // console.log(dateX);
        // console.log(dateY);
        const date1 = new Date(dateX);
        const date2 = new Date(dateY);
        // console.log(date1);
        // console.log(date2);
        const diffInMs = (date1 - date2);
        const days = diffInMs / (1000 * 60 * 60 * 24);
        // console.log("diff", days);
        return days ? days : 0;
    }
    // // console.log(initialValues);
    const validationSchema = Yup.object({
        // recieptdate: Yup.string().required("Required!").test('doc_check', `only last 7-8 days dates are accepted`, val => getDifferenceInDays(val) >= -800).test('check', 'invalid Date',val =>  getDifferenceInDays(val) <= 0),
        recieptdate: Yup.string().required("Required!").test('check', 'invalid Date', val => getDifferenceInDays(val) <= 0),
        studentClassYear: Yup.string().required("Required!"),
        feesAmount: Yup.string().required("Required!"),
        month: Yup.string().required("Required!"),
        Remark: Yup.string().required("Required!").trim(),
        isSettled: Yup.bool().required("Required!"),
    })

    // .test('date_check', `Date must be greater then ${new Date().getDate() - 1 + '-' + getMOnth() + '-' + new Date().getFullYear()}`, val => val?.slice(-2) >= (new Date().getDate()))
    // 

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async () => {


            const submitForm = async () => {

                setLoading(true)
                // // console.log(values);
                var obj = JSON.stringify({
                    "stdId": (StudentAccountData.accountInfo.stdId),
                    "remark": (formik.values.Remark),
                    "cmId": (formik.values.month),
                    "isSettled": (formik.values.isSettled),
                    "date": (formik.values.recieptdate),
                    "accId": (StudentAccountData.pendingFee.filter((ele) => `${ele.year}_${ele.revisionNumber}` === formik.values.studentClassYear)[0].accId),
                    "paymentMode":(formik.values.payBy),

                });

                var config = {
                    method: 'post',
                    url: AllUrl.generateBusPass,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    data: obj
                };
                try {
                    var result = await axios(config);
                    // if (result) setLoading(false)

                    if (result.status === 200) {
                        let backData = JSON.stringify({
                            "stdId": StudentAccountData.accountInfo.stdId,
                            "choice": localStorage.getItem('choice')
                        });
                        // console.log("______________________________________");
                        // console.log("backdata", backData);
                        // console.log("______________________________________");
                        let getBackData = {
                            method: 'post',
                            url: allUrls.allInfoOfActiveStudent,
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                            data: backData
                        };
                        accountAction3(getBackData, navigate, true, setLoading);
                        const link = document.createElement('a')
                        link.href = result.data;
                        link.target = '_blank';
                        link.download = `${formik.values.studentName}`;
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        clearForm();
                    }
                } catch (error) {
                    toast.warn(error.response.data.error, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false);
                }

            }


            Swal.fire({
                title: `Confirmation`,

                html: `Student- ${StudentName} , Amount- ${formik.values.feesAmount} , Insta No- ${formik.values.installmentNo} ` +
                    '<hr>' +
                    'Are you sure?' +
                    '<br>' +
                    `You want to generate fees receipt .`,
                showCancelButton: true,
                showConfirmButton: true,
                cancelButtonText: 'No',
                confirmButtonText: `Yes `,
                // confirmButtonText:'Deactive',
                reverseButtons: true,
                showCloseButton: true,
                // cancelButtonColor: 'gray',
                confirmButtonColor: "#F8A72C",

                showLoaderOnDeny: true,

                showClass: {
                    backdrop: 'swal2-noanimation', // disable backdrop animation
                    popup: '',                     // disable popup animation
                    icon: ''                       // disable icon animation
                },
                hideClass: {
                    popup: '',                     // disable popup fade-out animation
                }

            }).then(async (result) => {
                if (result.isConfirmed) {
                    submitForm()
                }
            })




        }
    })
    const clearForm = () => {


        formik.resetForm();
    }
    const [expanded, setExpanded] = React.useState({
        panel1: true
    });

    const handleChange = (id) => () => {
        // // console.log(expanded)
        setExpanded((pre) => {
            return {
                ...pre,
                [id]: !expanded[id]
            }

        });
    };

    const backToProfilePage = (e) => {
        e.preventDefault()
        let path = window.location.pathname;
        if (isStudentAdmin()) {
            // console.log("Navigated ");
            if (path.includes('dastudentprofile'))
                navigate('/student_admin_dashboard/dastudentprofile');
            else
                navigate('/student_admin_dashboard/studentprofile');
        }
        else if (isAccountAdmin()) {
            // console.log("Navigated ");
            if (path.includes('dastudentprofile'))
                navigate('/account_admin_dashboard/dastudentprofile');
            else
                navigate('/account_admin_dashboard/studentprofile');
        }
        else if (isSuperAdmin()) {
            // console.log("Navigated ");
            if (path.includes('dastudentprofile'))
                navigate('/admin_dashboard/dastudentprofile');
            else
                navigate('/admin_dashboard/studentprofile');
        }

    }

    useEffect(async () => {
        // console.log(formik.values);
        let acc = StudentAccountData.allAccounts.filter((ele) => `${ele.year}_${ele.revisionNumber}` === formik.values.studentClassYear)[0];
        formik.setFieldValue('feesAmount', acc.busFee);
        formik.setFieldValue('month', '-1')
        var config = {
            method: 'get',
            url: AllUrl.getChargebleMonth + `?sessionId=${acc.sessionId}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
        };

        var result = await axios(config);
        // console.log(result.data);
        if (result.status === 200)
            setChargebleMonth(result.data);
        else
            setChargebleMonth([]);
    }, [formik.values.studentClassYear])

    // // console.log('total fees::',totalFeesAmount,'totalReceivedFees',totalReceivedFees,'totalpendingFees',totalpendingFees);


    // console.log(StudentName);
    return (
        <>
            {loading && (
                <Loader />
            )}
            <div className=" p-3 m-2 me-3" style={{ backgroundColor: 'white', borderRadius: '8px' }}>

                <form onSubmit={formik.handleSubmit}>

                    <div className="row">
                        <div className="col">
                            <label className='addStdLable' htmlFor="">Father Name</label>
                            <input name='FatherName' onChange={formik.handleChange} value={formik.values.FatherName} type="text" className='form-control' placeholder='Father Name'

                                readOnly={true} />
                        </div>
                        <div className="col">
                            <label className='addStdLable' htmlFor="">Year</label>
                            <select name='studentClassYear' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.studentClassYear} className='form-select'  >
                                {/* <option defaultValue={(StudentAccountData.accountInfo.year)?.toString()}>{StudentAccountData.accountInfo.year}</option> */}
                                {/* {(StudentAccountData.accountInfo.year?.toString()) === 'I' &&
                                    <>
                                        <option value="I">I</option>
                                    </>
                                }
                                {(StudentAccountData.accountInfo.year?.toString()) === 'II' &&
                                    <>
                                        <option value="I">I</option>
                                        <option value="II">II</option>
                                    </>
                                }
                                {(StudentAccountData.accountInfo.year?.toString()) === 'III' &&
                                    <>
                                        <option value="I">I</option>
                                        <option value="II">II</option>
                                        <option value="III">III</option>
                                    </>
                                } */}
                                {(StudentAccountData.pendingFee.map((ele) => <option value={`${ele.year}_${ele.revisionNumber}`}>{ele.year} {ele.isLatest === 'true' ? "Current" : ""}</option>))}
                            </select>
                            {formik.errors.studentClassYear && formik.touched.studentClassYear ? (
                                <div className="text-danger fs-6">
                                    {formik.errors.studentClassYear}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col">
                            <label className='addStdLable' htmlFor="">Month</label>
                            <select name='month' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.month} className='form-select' >
                                <option defaultValue="-1">Select Month</option>
                                {(chargebleMonth.map((ele) => <option value={`${ele.cmId}`}>{ele.month} {ele.year}</option>))}
                            </select>
                            {formik.errors.month && formik.touched.month ? (
                                <div className="text-danger fs-6">
                                    {formik.errors.month}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="col">
                            <label className='addStdLable' htmlFor="">Receipt Date</label>
                            <input name='recieptdate' onBlur={formik.handleBlur} max="9999-12-31" onChange={formik.handleChange} value={formik.values.recieptdate} type="date" className='form-control' placeholder='Date' />
                            {formik.errors.recieptdate && formik.touched.recieptdate ? (
                                <div className="text-danger fs-6">
                                    {formik.errors.recieptdate}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className='col'>
                            <label className='addStdLable' htmlFor="">{" "}</label>
                            <div className='col d-flex '>
                                <input type='checkbox'
                                    // checked={true}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="isSettled"
                                    value={formik.values.isSettled}
                                    checked={formik.values.isSettled}
                                    className='m-2'
                                />{' '}Waive Off

                            </div>
                            {formik.errors.isSettled && formik.touched.isSettled ? (
                                <div className="text-danger fs-6">
                                    {formik.errors.isSettled}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="col">
                            <label className='addStdLable' htmlFor="">Amount</label>
                            <Numberformat name='feesAmount' onBlur={formik.handleBlur} thousandSeparator={true}
                                thousandsGroupStyle='lakh' disabled={true} onChange={formik.handleChange} value={formik.values.feesAmount} className='form-control' placeholder='Amount' />
                            {formik.errors.feesAmount && formik.touched.feesAmount ? (
                                <div className="text-danger fs-6">
                                    {formik.errors.feesAmount}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col">
                            <label className='addStdLable' htmlFor="">Pay By</label>
                            <select name="payBy" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.payBy} className='form-select'>
                                <option defaultValue="Cash">Cash</option>
                                <option value="Online">Online</option>
                            </select>
                            {formik.errors.payBy && formik.touched.payBy ? (
                                <div className="text-danger fs-6">
                                    {formik.errors.payBy}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col">
                            <label className='addStdLable' htmlFor="">Remark</label>
                            <input name='Remark' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.Remark} type="text" className='form-control' placeholder='Remark' />
                            {formik.errors.Remark && formik.touched.Remark ? (
                                <div className="text-danger fs-6">
                                    {formik.errors.Remark}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>


                    <div className="row mt-3 ">
                        <div className='col-7'>
                        </div>

                        <div className=' col-5 d-flex justify-content-end'>

                            <Link className='m-1 me-5 ' onClick={backToProfilePage} to='#!'>Go To Profile</Link>
                            <button className="btn btn-md btn-outline-warning fw-bold  " style={{ width: "200px", backgroundColor: 'white', color: 'orange' }} type="submit" disabled={loading} >Generate Receipt</button>
                        </div>

                    </div>

                </form>
            </div>

            <Accordion className="my-2 me-3 ms-2" expanded={true} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: '#E6E9F4 ', borderBottom: '2px solid orange', maxHeight: "50px", minHeight: "50px"
                    }}
                >
                    <Typography style={{ color: "#414c97", margin: "0px" }}><b> Pending bus Fees Status</b></Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#F4F7FC', padding: '15px' }}>
                    <Typography component={'div'} >
                        {/* Personal Details */}

                        <div className="row m-1 my-2 p-1 pt-2 fw-bold" style={{ backgroundColor: 'white', color: '#5A607F' }}>
                            <div className="col">
                                <label className='addStdLable' htmlFor="">Year</label>
                            </div>
                            <div className="col">
                                <label className='addStdLable' htmlFor=""> Total bus Fees</label>
                            </div>
                            <div className="col">
                                <label className='addStdLable' htmlFor="">Recieved bus Fees</label>
                            </div>
                            <div className="d-flex col-2">
                                <label className='addStdLable' htmlFor="">Pending bus Fees</label>
                            </div>
                            <div className="d-flex col-2">
                                <label className='addStdLable' htmlFor="">Settled bus Fees</label>
                            </div>
                        </div>
                        {StudentAccountData.pendingBusFees.map(pendingFee => (

                            <div className="row m-1 my-2 p-1 pt-2" style={{ backgroundColor: 'white' }}>
                                <div className="col">
                                    <label htmlFor="">{pendingFee.year === 'I' ? '1st Year' : pendingFee.year === 'II' ? '2nd Year' : '3rd Year'}<b style={{ color: "red" }}> {pendingFee.revisionNumber > 0 ? ' Attempt ' + (pendingFee.revisionNumber) : ""}  {pendingFee.isLatest === "true" ? " *" : ''}</b> </label>
                                </div>
                                <div className="col">
                                    <label htmlFor="">{pendingFee.feesAmount}</label>
                                </div>
                                <div className="col">
                                    <label htmlFor="">{pendingFee.receivedFees}</label>
                                </div>
                                <div className="d-flex col-2">
                                    <label htmlFor="">{pendingFee.pendingFees}</label>
                                </div>
                                <div className="d-flex col-2">
                                    <label htmlFor="">{pendingFee.settledFees}</label>
                                </div>
                            </div>
                        ))}



                        <div className="row m-1 my-2 p-1 pt-2 " style={{ backgroundColor: '#dadadb ' }}>
                            <div className="col">
                                <label className='addStdLable' htmlFor="">Total</label>
                            </div>
                            <div className="col">
                                <label className='addStdLable' htmlFor="">{totalFeesAmount}</label>
                            </div>
                            <div className="col">
                                <label className='addStdLable' htmlFor="">{totalReceivedFees}</label>
                            </div>
                            <div className="d-flex col-2">
                                <label className='addStdLable' htmlFor="">{totalpendingFees} </label>
                            </div>
                            <div className="d-flex col-2">
                                <label className='addStdLable' htmlFor="">{totalSettledFees} </label>
                            </div>

                        </div>





                    </Typography>
                </AccordionDetails>
            </Accordion>


            <Accordion className="my-2 me-3 ms-2 mb-5" expanded={expanded.panel1 === true} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: '#E6E9F4 ', borderBottom: '2px solid orange', maxHeight: "50px", minHeight: "50px"
                    }}
                >
                    <Typography style={{ color: "#414c97", margin: "0px" }}><b>Bus Pass History</b></Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#F4F7FC', padding: '15px' }}>
                    <Typography component={'div'} >
                        <div className=''>
                            <div className="row m-1 ">
                                {busPassData.reverse().map(StudentAccountData => (
                                    <div className='col-4'>
                                        <div className='mx-5 my-3 shadow'>


                                            <div className='row p-1' style={{ fontSize: '12px', backgroundColor: '#FE9117', color: 'white', borderRadius: '10px 10px  0 0 ' }}>Sant Singaji Institute Of Science & Management</div>

                                            <div className="row p-1" style={{ backgroundColor: '#FFF7E7' }}>
                                                <div className="col">
                                                    <p className='p-1 m-0 fw-medium' style={{ fontSize: '14px', color: '#656A87' }}>
                                                        <span style={{ fontSize: '11px' }}>Bus Pass for</span>
                                                        <br />
                                                        {StudentName}
                                                    </p>
                                                </div>
                                                <div className="d-flex col justify-content-start ">
                                                    <p className='p-1' style={{ fontSize: '12px', margin: "auto", color: 'white' }}>{StudentAccountData.accountsReceiptNo}</p>
                                                </div>
                                            </div>
                                            <div className="row p-1" style={{ backgroundColor: 'white', borderRadius: '0 0 10px 10px ' }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>
                                                            <span style={{ fontSize: '13px' }}>Date</span>
                                                            <br />
                                                            <b>
                                                                {StudentAccountData.date ? StudentAccountData.date.split("-").reverse().join("-") : '-'}
                                                            </b>
                                                        </p>
                                                    </div>
                                                    <div className="d-flex col justify-content-start ">
                                                        <p className='p-1 m-0 ms-2  ' style={{ fontSize: '15px', color: '#656A87' }}>
                                                            <span style={{ fontSize: '13px', paddingRight: "23px" }}>Class</span> <br />
                                                            <b> {StudentClassName + '-' + StudentAccountData.accYear} <b style={{ color: "red" }}> {StudentAccountData.revisionNumber > 0 ? ' Attempt ' + (StudentAccountData.revisionNumber) : ""} </b>
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>
                                                            <span style={{ fontSize: '13px' }}>Month</span>
                                                            <br />
                                                            <b>

                                                                {StudentAccountData.month}
                                                            </b>
                                                        </p>
                                                    </div>
                                                    <div className="d-flex col justify-content-start ">
                                                        <p className='p-1 m-0 ms-2  ' style={{ fontSize: '15px', color: '#656A87' }}>
                                                            <span style={{ fontSize: '13px', paddingRight: "23px" }}>Year</span> <br />
                                                            <b> {StudentAccountData.year} </b>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div style={{backgroundColor:'#FE9117', height:'2px', marginTop:'5px', marginBottom: '5px'}}></div>

                                                {/* <div className="d-flex col m-2" style={{ backgroundColor: (StudentAccountData.status === 'paid') ? '#198754' : StudentAccountData.status === 'settled' ? '#6b79cd' : '#f55b5b', borderRadius: '5px' }}> */}
                                                    <div className="col">
                                                        <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>

                                                           <b> ₹ {StudentAccountData?.amount}  </b><br />
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex align-items-center">
                                                        <div style={{backgroundColor: (StudentAccountData.status === 'paid') ? '#198754' : StudentAccountData.status === 'settled' ? '#6b79cd' : '#f55b5b', height:'15px', width: '15px', borderRadius: '50%'}} className='me-1'></div>
                                                        <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>

                                                           <b> {StudentAccountData.status} </b> <br />
                                                        </p>
                                                    </div>
                                                {/* </div> */}

                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>

                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

//passing the userData in fetchUsers function and also dispatch method
const mapDispatchToProps = (dispatch) => {
    return {
        accountAction3: (config, navigate, is_reciptBtn, setLoading) => dispatch(accountAction3(config, navigate, is_reciptBtn, setLoading)),
    };
};

//Connecting the component to our store
export default connect(null, mapDispatchToProps)(BusPass);

