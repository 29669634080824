/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../styles/AddNewStudent.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import AllUrl from "../../../redux/constants/url";
import Loader from "../../assests/common/Loader";
import { connect } from "react-redux";
import allUrls from "../../../redux/constants/url";
import { accountAction4 } from "../../../redux/actionDispatcher/superAdmin/studentAccountTableDataDispatcher";
import axios from "axios";
import { Modal } from "react-bootstrap";
import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Table from 'editorjs-table';
import Checklist from '@editorjs/checklist';
import Marker from "@editorjs/marker";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import './Styles/studentActivities.css';
import { updateActivityStatus } from "../../../redux/actionDispatcher/superAdmin/updateActivityStatusDispatcher";
import delete_icon from '../../assests/image/delete_Icon.svg'
import StudentProfile from './StudentProfile.js'
function StudentActivities({ accountAction4, updateActivityStatus }) {
  const [addModalShow, setAddModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [reportData1, setReportData] = useState(null);

  const token = localStorage.getItem("token");
  const choosedReportId = localStorage.getItem("chosedREport");
  const navigate = useNavigate();
  const editorInstance = useRef();
  const editorInstance2 = useRef();
  const data = localStorage.getItem("userEdit");
  const StudentAccountData = JSON.parse(data)
  const [loading, setLoading] = useState(false);
  const [allAdminInfo, setAllAdminInfo] = useState([]);
  const allActivityData = StudentAccountData?.student_all_reports;
  var StudentName = ''
  StudentName = StudentAccountData.accountInfo.firstName + ' ' + StudentAccountData.accountInfo.lastName
  useEffect(async () => {
    var config = {
      method: "get",
      url: AllUrl?.infoAllAdmin,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    var result = await axios(config);
    if (result.status === 200) setAllAdminInfo(result.data);
    else setAllAdminInfo([]);
  }, []);

  const [expanded, setExpanded] = React.useState({
    panel1: true
  });

  const handleChange = (id) => () => {
    setExpanded((pre) => {
      return {
        ...pre,
        [id]: !expanded[id]
      }
    });
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  const getSingleReportData = async (reportId) => {
    setLoading(true);
    var configSingleReport = {
      method: "get",
      url: `${AllUrl?.getSingleReportById}/${reportId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    var result = await axios(configSingleReport);
    if (result.status === 200) {
      setReportData(result.data);
      setLoading(false);
      setUpdateModalShow(true);
    }
    else {
      setReportData(null);
    }
  }

  const AddActivityModal = (props) => {
    const { allAdminInfo } = props;
    var [descriptionData1, setDescriptionData1] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [expanded2, setExpanded2] = React.useState({
      panel2: false
    });

    const handleChange2 = (id) => () => {
      setExpanded2((pre) => {
        return {
          ...pre,
          [id]: !expanded2[id]
        }
      });
    };

    const initialValues = {
      stdId: '',
      activityType: 'general',
      disscussTo: '',
      description: '',
      priority: 'low',
      isPrivate: false,
      status: 'open',
      activitySubject: '',
    };

    const validationSchema = Yup.object({
      activityType: Yup.string().required('Required'),
      priority: Yup.string().required('Required'),
      status: Yup.string().required('Required'),
      activitySubject: Yup.string().required('Required'),
    });

    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        let base64StringsArray = selectedFiles?.map((item) => item.base64String);
        const submitForm = async () => {
          setLoading(true);
          var newActivityData = {
            "stdId": (StudentAccountData?.accountInfo?.stdId),
            "activityType": (formik?.values?.activityType),
            "disscussTo": (formik.values.disscussTo === "-1" ? "" : formik.values.disscussTo),
            "description": descriptionData1,
            "priority": (formik.values.priority),
            "isPrivate": formik.values.activityType === 'general' ? "false" : JSON.stringify(formik.values?.isPrivate),
            "status": (formik.values.status),
            "activitySubject": (formik.values.activitySubject),
            "attachments": base64StringsArray,
          };

          const activityConfig = {
            method: 'post',
            url: AllUrl.generateActivity,
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            data: newActivityData
          };

          try {
            var result = await axios(activityConfig);
            if (result.status === 201) {
              setAddModalShow(false);
              let backData = JSON.stringify({
                "stdId": StudentAccountData.accountInfo.stdId,
                "choice": localStorage.getItem('choice')
              });
              let getBackConfig = {
                method: 'post',
                url: allUrls.allInfoOfActiveStudent,
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                },
                data: backData
              };
              accountAction4(getBackConfig, navigate, true, setLoading);

              var notifyConfig = {
                method: "get",
                url: AllUrl?.getNotificationCount,
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              };

              updateActivityStatus(notifyConfig, navigate);
              clearForm();
              setSelectedFiles([]);
              base64StringsArray = [];
            }
          } catch (error) {
            toast.warn(error.response.data.error, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          }
        }
        Swal.fire({
          title: `Confirmation`,

          html: `Student - ${StudentName} ` +
            '<hr>' +
            'Are you sure?' +
            '<br>' +
            `You want to generate Report.`,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: `Yes `,
          reverseButtons: true,
          showCloseButton: true,
          confirmButtonColor: "#F8A72C",
          showLoaderOnDeny: true,
          showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: ''                       // disable icon animation
          },
          hideClass: {
            popup: '',                     // disable popup fade-out animation
          }

        }).then(async (result) => {
          if (result.isConfirmed) {
            submitForm()
          }
        })
      }
    });
    const clearForm = () => {
      formik.resetForm();
    }
    const initEditor = () => {
      const editor = new EditorJS({
        holder: 'editorjs',
        onReady: () => {
          editorInstance.current = editor;
        },
        autofocus: true,
        onChange: async () => {
          editor.saver?.save().then((result) => {
            if (result) {
              setDescriptionData1(result);
            }
          }).catch((err) => {
            // console.log('editor error', err);
          })
        },
        tools: {
          header: Header,
          list: List,
          table: Table,
          checklist: Checklist,
          Marker: {
            class: Marker,
            shortcut: 'CTRL+SHIFT+M',
          }
        },
      });
    }

    useEffect(() => {
      if (editorInstance.current === null) {
        initEditor();
      }

      return () => {
        editorInstance?.current?.destroy();
        editorInstance.current = null;
      }

    }, []);

    const convertFilesToBase64 = async (e) => {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          setSelectedFiles((prevSelectedFiles) => [
            ...prevSelectedFiles,
            { file, base64String },
          ]);
        };
        reader.readAsDataURL(file);
      }
    };

    const fileTypeIcons = {
      pdf: 'fas fa-file-pdf',
      docx: 'fas fa-file-word',
      mp4: 'fas fa-file-video',
      jpg: 'fas fa-image',
      jpeg: 'fas fa-image',
      png: 'fas fa-image',
      svg: 'fas fa-image',
      mp3: 'fas fa-file-audio',
      csv: 'fas fa-file-excel',
      xlsx: 'fas fa-file-excel',
    };

    const getFileType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      if (fileTypeIcons.hasOwnProperty(extension)) {
        return fileTypeIcons[extension];
      }
      return 'fas fa-file';
    };


    const truncateFileName = (fileName, maxLength) => {
      const extension = fileName.split('.').pop().toLowerCase();
      if (fileName.length <= maxLength) {
        return fileName;
      }
      const truncatedName = fileName.substring(0, maxLength - extension.length - 4) + '...' + extension;
      return truncatedName;
    };

    const handleDeleteFile = (index) => {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      setSelectedFiles(updatedFiles);
    };

    return (
      <Modal
        onHide={props.onHide}
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        contentClassName={'custom_modal'}
        dialogClassName={'modal_scroll'}
      >
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col">
                <label className='addStdLable' htmlFor="">Activity Type</label>
                <select name='activityType' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values?.activityType} className='form-select' >
                  <option value='general'>General</option>
                  <option value='academic'>Academic</option>
                  <option value='account'>Account</option>
                  <option value='attendance'>Attendance</option>
                </select>
                {formik.errors?.activityType && formik.touched?.activityType ? (
                  <div className="text-danger fs-6">
                    {formik.errors?.activityType}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col">
                <label className='addStdLable' htmlFor="">Discused With</label>
                <select
                  name='disscussTo'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.disscussTo}
                  className='form-select'
                >
                  <option value="-1">Select Assigned To</option>
                  {allAdminInfo.map((item) => {
                    if (item.isActive === 1) {
                      return (
                        <option key={item.userId} value={item.userId}>
                          {item.name}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
                {formik.errors.disscussTo && formik.touched.disscussTo ? (
                  <div className="text-danger fs-6">
                    {formik.errors.disscussTo}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label className='addStdLable' htmlFor="">{" "}</label>
                <div className='col d-flex '>
                  <input type='checkbox'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="isPrivate"
                    value={formik.values.isPrivate}
                    checked={
                      formik?.values?.isPrivate === true ? true : false
                    }
                    className='m-2'
                    disabled={
                      formik.values?.activityType !== "general" ? false : true
                    }
                  />{' '}Is Private

                </div>
                {formik.errors.isPrivate && formik.touched.isPrivate ? (
                  <div className="text-danger">
                    {formik.errors.isPrivate}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col">
                <label className='addStdLable' htmlFor="">Priority</label>
                <select name='priority' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.priority} className='form-select' >
                  <option value='low'>Low</option>
                  <option value='medium'>Medium</option>
                  <option value='high'>High</option>
                </select>
                {formik.errors.priority && formik.touched.priority ? (
                  <div className="text-danger fs-6">
                    {formik.errors.priority}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className='row'>
              <div className="col">
                <label className='addStdLable' htmlFor="">Activity Subject</label>
                <input name='activitySubject' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.activitySubject} type="text" className='form-control' placeholder='Activity Subject' />
                {formik.errors.activitySubject && formik.touched.activitySubject ? (
                  <div className="text-danger fs-6">
                    {formik.errors.activitySubject}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row d-flex mt-2">
              <div>
                <Accordion className="my-2 me-3 ms-2 mb-5" expanded={expanded2.panel2 === true} onChange={handleChange2('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: '#E6E9F4 ', borderBottom: '2px solid orange', maxHeight: "50px", minHeight: "50px"
                    }}
                  >
                    <Typography style={{ color: "#414c97", margin: "0px" }}>
                      <div>
                        <button type='button' className='btn btn-warning text-light fw-bold addnew_btn' onClick={() => { document.getElementById("profilePhoto").click() }}>Choose Files</button>
                        <input
                          type="file"
                          id="profilePhoto"
                          accept="*/*"
                          multiple
                          style={{ display: 'none' }}
                          onChange={convertFilesToBase64}
                        />
                      </div>
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails style={{ backgroundColor: '#F4F7FC', padding: '15px' }}>
                    <Typography component={'div'} >
                      <div className='attach_box' id="style-1">
                        <div className="row m-1 ">
                          {selectedFiles?.length > 0 ? (
                            <ul>
                              {selectedFiles.map((item, index) => (
                                <li key={index} className='file_li my-2'>
                                  <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex align-items-center'>
                                      <div className='file_icon me-4'><i className={getFileType(item.file.name)}></i></div>
                                      <div>{truncateFileName(item.file.name, 25)}</div>
                                    </div>
                                    <div><button style={{ background: 'transparent' }} type='button' onClick={() => {
                                      Swal.fire({
                                        title: `Confirmation`,

                                        html: `File -> ${truncateFileName(item.file.name, 25)} ` +
                                          '<hr>' +
                                          'Are you sure?' +
                                          '<br>' +
                                          `You want to Delete Attachment.`,
                                        showCancelButton: true,
                                        showConfirmButton: true,
                                        cancelButtonText: 'No',
                                        confirmButtonText: `Yes `,
                                        reverseButtons: true,
                                        showCloseButton: true,
                                        confirmButtonColor: "#F8A72C",
                                        showLoaderOnDeny: true,
                                        showClass: {
                                          backdrop: 'swal2-noanimation', // disable backdrop animation
                                          popup: '',                     // disable popup animation
                                          icon: ''                       // disable icon animation
                                        },
                                        hideClass: {
                                          popup: '',                     // disable popup fade-out animation
                                        }

                                      }).then(async (result) => {
                                        if (result.isConfirmed) {
                                          handleDeleteFile(index);
                                        }
                                      })
                                    }}><img src={delete_icon} alt="" /></button></div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <h2 style={{ color: '#5A607F' }}>No Reports Found</h2>
                            </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            <div className="row">
              <label className='addStdLable mt-3' htmlFor="">Activity Description</label>
              <div className='editor_box mt-1'>
                <div id='editorjs'></div>
              </div>
            </div>

            <div className='d-flex justify-content-center mt-5 mb-3'>
              <button className="btn btn-md btn-outline-warning fw-bold" style={{ width: "200px", backgroundColor: 'white', color: 'orange' }} type="submit" disabled={loading} >Generate Activity</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  const UpdateActivityModal = (props) => {
    const { allAdminInfo, reportData } = props;
    var [descriptionData, setDescriptionData] = useState(reportData !== undefined && reportData !== null ? JSON.parse(reportData?.description) : null);
    const [selectedFiles2, setSelectedFiles2] = useState([]);
    const [expanded3, setExpanded3] = React.useState({
      panel3: false
    });

    const handleChange3 = (id) => () => {
      setExpanded3((pre) => {
        return {
          ...pre,
          [id]: !expanded3[id]
        }
      });
    };

    const initialValues = {
      stdId: reportData?.stdId,
      activityType: reportData?.activityType,
      disscussTo: reportData?.disscussTo,
      description: descriptionData,
      priority: reportData?.priority,
      isPrivate: reportData?.isPrivate !== undefined && reportData.isPrivate !== null ? JSON.parse(reportData?.isPrivate) : '',
      status: reportData?.status,
      activitySubject: reportData?.activitySubject,
    };

    const validationSchema = Yup.object({
      activityType: Yup.string().required('Required'),
      priority: Yup.string().required('Required'),
      status: Yup.string().required('Required'),
      activitySubject: Yup.string().required('Required'),
    });

    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        let base64StringsArray2 = selectedFiles2?.map((item) => item.base64String);
        const submitForm = async () => {
          setLoading(true);
          var newUpdateData = {
            "id": (reportData?.id),
            "stdId": (reportData?.stdId),
            "activityType": (formik?.values?.activityType),
            "disscussTo": (formik.values.disscussTo === "-1" ? "" : formik.values.disscussTo),
            "description": descriptionData,
            "priority": (formik.values.priority),
            "isPrivate": formik.values.activityType === 'general' ? "false" : JSON.stringify(formik.values?.isPrivate),
            "status": (formik.values.status),
            "activitySubject": (formik.values.activitySubject),
            "attachments": base64StringsArray2,
          };

          const activityConfig = {
            method: 'post',
            url: AllUrl.updateActivity,
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            data: newUpdateData
          };

          try {
            var result = await axios(activityConfig);
            if (result.status === 200) {
              setUpdateModalShow(false);
              let backData = JSON.stringify({
                "stdId": reportData?.stdId,
                "choice": localStorage.getItem('choice')
              });
              let getBackConfig = {
                method: 'post',
                url: allUrls.allInfoOfActiveStudent,
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                },
                data: backData
              };

              accountAction4(getBackConfig, navigate, true, setLoading);
              var notifyConfig = {
                method: "get",
                url: AllUrl?.getNotificationCount,
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              };

              updateActivityStatus(notifyConfig, navigate);
              clearForm();
            }
          } catch (error) {
            toast.warn(error.response.data.error, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          }
        }
        Swal.fire({
          title: `Confirmation`,

          html: `Student - ${StudentName}` +
            '<hr>' +
            'Are you sure?' +
            '<br>' +
            `You want to Update Activity.`,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: `Yes `,
          reverseButtons: true,
          showCloseButton: true,
          confirmButtonColor: "#F8A72C",

          showLoaderOnDeny: true,

          showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: ''                       // disable icon animation
          },
          hideClass: {
            popup: '',                     // disable popup fade-out animation
          }

        }).then(async (result) => {
          if (result.isConfirmed) {
            submitForm();
          }
        })
      }
    });
    const clearForm = () => {
      formik.resetForm();
    }

    const initEditor2 = () => {
      if (descriptionData && document.getElementById('editorjs2')?.children.length < 1) {
        const editor = new EditorJS({
          holder: 'editorjs2',
          onReady: () => {
            if (!editorInstance2.current)
              editorInstance2.current = editor;
          },
          autofocus: false,
          onChange: async () => {
            editor.saver?.save().then((result) => {
              if (result) {
                setDescriptionData(result);
              }
            }).catch((err) => {
              // console.log('editor error', err);
            })
          },
          tools: {
            header: Header,
            list: List,
            table: Table,
            checklist: Checklist,
            Marker: {
              class: Marker,
              shortcut: 'CTRL+SHIFT+M',
            }
          },
          data: descriptionData
        });
      }
    }

    useEffect(() => {
      if (editorInstance2.current === null && reportData !== undefined && reportData !== null) {
        initEditor2();
      }
      return () => {
        editorInstance2?.current?.destroy();
        editorInstance2.current = null;
      }
    }, [reportData]);

    const handleDisabledPrivate = (val) => {
      if (val?.activityType === "general") {
        return true;
      } else {
        return false;
      }
    }

    const convertFilesToBase642 = async (e) => {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64String = event.target.result;
          setSelectedFiles2((prevSelectedFiles) => [
            ...prevSelectedFiles,
            { file, base64String },
          ]);
        };
        reader.readAsDataURL(file);
      }
    };

    console.log('Selected Files Are :', selectedFiles2);
    const fileTypeIcons = {
      pdf: 'fas fa-file-pdf',
      docx: 'fas fa-file-word',
      mp4: 'fas fa-file-video',
      jpg: 'fas fa-image',
      jpeg: 'fas fa-image',
      png: 'fas fa-image',
      svg: 'fas fa-image',
      mp3: 'fas fa-file-audio',
      csv: 'fas fa-file-excel',
      xlsx: 'fas fa-file-excel',
    };

    const getFileType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      if (fileTypeIcons.hasOwnProperty(extension)) {
        return fileTypeIcons[extension];
      }
      return 'fa-solid fa-file';
    };

    const truncateFileName = (fileName, maxLength) => {
      const extension = fileName.split('.').pop().toLowerCase();
      if (fileName.length <= maxLength) {
        return fileName;
      }
      const truncatedName = fileName.substring(0, maxLength - extension.length - 4) + '...' + extension;
      return truncatedName;
    };

    const handleDeleteFile2 = (index) => {
      const updatedFiles2 = [...selectedFiles2];
      updatedFiles2.splice(index, 1);
      setSelectedFiles2(updatedFiles2);
    };

    return (
      <Modal
        onHide={props.onHideUpdate}
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        contentClassName={'custom_modal'}
      >
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col">
                <label className='addStdLable' htmlFor="">Activity Type</label>
                <select name='activityType' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values?.activityType} className='form-select' >
                  <option value='general'>General</option>
                  <option value='academic'>Academic</option>
                  <option value='account'>Account</option>
                  <option value='attendance'>Attendance</option>
                </select>
                {formik.errors?.activityType && formik.touched?.activityType ? (
                  <div className="text-danger fs-6">
                    {formik.errors?.activityType}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col">
                <label className='addStdLable' htmlFor="">Discused With</label>
                <select
                  name='disscussTo'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.disscussTo}
                  className='form-select'
                >
                  <option value="-1">Select Assigned To</option>
                  {allAdminInfo.map((item) => {
                    if (item.isActive === 1) {
                      return (
                        <option key={item.userId} value={item.userId}>
                          {item.name}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
                {formik.errors.disscussTo && formik.touched.disscussTo ? (
                  <div className="text-danger fs-6">
                    {formik.errors.disscussTo}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className='row'>
              <div className="col">
                <label className='addStdLable' htmlFor="">Priority</label>
                <select name='priority' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.priority} className='form-select' >
                  <option value='low'>Low</option>
                  <option value='medium'>Medium</option>
                  <option value='high'>High</option>
                </select>
                {formik.errors.priority && formik.touched.priority ? (
                  <div className="text-danger fs-6">
                    {formik.errors.priority}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col">
                <label className='addStdLable' htmlFor="">Status</label>
                <select name='status' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik?.values?.status} className='form-select' >
                  {
                    reportData?.status === 'open' ? (
                      <>
                        <option value='open'>Open</option>
                        <option value='close'>Close</option>
                      </>
                    ) : reportData?.status === 'close' ? (
                      <>
                        <option value='close'>Close</option>
                        <option value='reopen'>Re-open</option>
                      </>
                    ) : reportData?.status === 'reopen' ? (
                      <>
                        <option value='reopen'>Re-open</option>
                        <option value='close'>Close</option>
                      </>
                    ) : ''
                  }
                </select>
                {formik.errors.status && formik.touched.status ? (
                  <div className="text-danger fs-6">
                    {formik.errors.status}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className='row'>
              <div className="col">
                <label className='addStdLable' htmlFor="">Activity Subject</label>
                <input name='activitySubject' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.activitySubject} type="text" className='form-control' placeholder='Activity Subject' />
                {formik.errors.activitySubject && formik.touched.activitySubject ? (
                  <div className="text-danger fs-6">
                    {formik.errors.activitySubject}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className='col'>
                <label className='addStdLable' htmlFor="">{" "}</label>
                <div className='col d-flex '>
                  <input type='checkbox'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="isPrivate"
                    value={formik.values.isPrivate}
                    checked={
                      formik?.values?.isPrivate === true ? true : false
                    }
                    className='m-2'
                    disabled={
                      handleDisabledPrivate(formik.values)
                    }
                  />{' '}Is Private

                </div>
                {formik.errors.isPrivate && formik.touched.isPrivate ? (
                  <div className="text-danger">
                    {formik.errors.isPrivate}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row d-flex mt-2">
              <div>
                <Accordion className="my-2 me-3 ms-2 mb-5" expanded={expanded3.panel3 === true} onChange={handleChange3('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: '#E6E9F4 ', borderBottom: '2px solid orange', maxHeight: "50px", minHeight: "50px"
                    }}
                  >
                    <Typography style={{ color: "#414c97", margin: "0px" }}>
                      <div>
                        <button type='button' className='btn btn-warning text-light fw-bold addnew_btn' onClick={() => { document.getElementById("profilePhoto2").click() }}>Choose Files</button>
                        <input
                          type="file"
                          id="profilePhoto2"
                          accept="*/*"
                          multiple
                          style={{ display: 'none' }}
                          onChange={convertFilesToBase642}
                        />
                      </div>
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails style={{ backgroundColor: '#F4F7FC', padding: '15px' }}>
                    <Typography component={'div'} >
                      <div className='attach_box' id="style-1">
                        <div className="row m-1 ">
                          {selectedFiles2?.length > 0 || reportData?.attachmentUrl?.length > 0 ? (
                            <>
                              {reportData?.attachmentUrl?.length > 0 ?
                                <ul>
                                  {reportData.attachmentUrl?.map((item, index) => (
                                    <>
                                      <a href={item} className='dock_link' rel="noreferrer" target='_blank'>
                                        <li key={index} className='file_li my-2'>
                                          <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                              <div className='file_icon me-4'><i className="fas fa-file"></i></div>
                                              <div>{`Attachment_${index + 1}`}</div>
                                            </div>
                                            <div><button style={{ background: 'transparent' }} type='button' onClick={() => {
                                              Swal.fire({
                                                title: `Confirmation`,

                                                html: `File ->  Attachment_${index + 1}` +
                                                  '<hr>' +
                                                  'Are you sure?' +
                                                  '<br>' +
                                                  `You want to Delete Attachment.`,
                                                showCancelButton: true,
                                                showConfirmButton: true,
                                                cancelButtonText: 'No',
                                                confirmButtonText: `Yes `,

                                                reverseButtons: true,
                                                showCloseButton: true,
                                                confirmButtonColor: "#F8A72C",

                                                showLoaderOnDeny: true,

                                                showClass: {
                                                  backdrop: 'swal2-noanimation', // disable backdrop animation
                                                  popup: '',                     // disable popup animation
                                                  icon: ''                       // disable icon animation
                                                },
                                                hideClass: {
                                                  popup: '',                     // disable popup fade-out animation
                                                }

                                              }).then(async (result) => {
                                                if (result.isConfirmed) {
                                                  const deleteAttachmentApi = async () => {
                                                    setLoading(true);
                                                    var deleteAttachmentData = {
                                                      "id": (reportData?.id),
                                                      "attachmentUrl": (item),
                                                    };

                                                    const attachmentConfig = {
                                                      method: 'post',
                                                      url: AllUrl.deleteOneAttachment,
                                                      headers: {
                                                        'Authorization': `Bearer ${token}`,
                                                        'Content-Type': 'application/json'
                                                      },
                                                      data: deleteAttachmentData
                                                    };

                                                    try {
                                                      var result = await axios(attachmentConfig);
                                                      if (result.status === 200) {
                                                        setLoading(false);
                                                        getSingleReportData(choosedReportId);
                                                        setLoading(false);
                                                      }
                                                    } catch (error) {
                                                      toast.warn(error.response.data.error, {
                                                        position: "top-center",
                                                        autoClose: 3000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                      });
                                                      setLoading(false);
                                                    }
                                                  }
                                                  deleteAttachmentApi();
                                                }
                                              })
                                            }}><img src={delete_icon} alt="" /></button></div>
                                          </div>
                                        </li>
                                      </a>
                                    </>
                                  ))}
                                </ul> : null
                              }
                              <ul>
                                {selectedFiles2?.map((item, index) => (
                                  <li key={index} className='file_li my-2'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      <div className='d-flex align-items-center'>
                                        <div className='file_icon me-4'><i className={getFileType(item.file.name)}></i></div>
                                        <div>{truncateFileName(item.file.name, 25)}</div>
                                      </div>
                                      <div><button style={{ background: 'transparent' }} type='button' onClick={() => {
                                        Swal.fire({
                                          title: `Confirmation`,

                                          html: `File -> ${truncateFileName(item.file.name, 25)} ` +
                                            '<hr>' +
                                            'Are you sure?' +
                                            '<br>' +
                                            `You want to Delete Attachment.`,
                                          showCancelButton: true,
                                          showConfirmButton: true,
                                          cancelButtonText: 'No',
                                          confirmButtonText: `Yes `,
                                          reverseButtons: true,
                                          showCloseButton: true,
                                          confirmButtonColor: "#F8A72C",
                                          showLoaderOnDeny: true,
                                          showClass: {
                                            backdrop: 'swal2-noanimation', // disable backdrop animation
                                            popup: '',                     // disable popup animation
                                            icon: ''                       // disable icon animation
                                          },
                                          hideClass: {
                                            popup: '',                     // disable popup fade-out animation
                                          }

                                        }).then(async (result) => {
                                          if (result.isConfirmed) {
                                            handleDeleteFile2(index);
                                          }
                                        })
                                      }}><img src={delete_icon} alt="" /></button></div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <h2 style={{ color: '#5A607F' }}>No Reports Found</h2>
                            </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            <div className="row">
              <label className='addStdLable' htmlFor="">Activity Description</label>
              <div className='editor_box'>
                <div id='editorjs2' style={{ paddingBottom: "0px" }}></div>
              </div>
            </div>

            <div className='d-flex justify-content-center mt-5 mb-3'>
              <button className="btn btn-md btn-outline-warning fw-bold  " style={{ width: "200px", backgroundColor: 'white', color: 'orange' }} type="submit" disabled={loading} >Update Activity</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {loading && <Loader />}
      <StudentProfile />
      <div className="d-flex justify-content-end pe-5">
        <button
          onClick={() => setAddModalShow(true)}
          className="btn  btn-warning text-light fw-bold addnew_btn"
          type="submit"
        >
          <i className="fas fa-plus pr-2"></i> Add New Activity
        </button>
      </div>

      <Accordion className="my-2 me-3 ms-2 mb-5" expanded={expanded.panel1 === true} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: '#E6E9F4 ', borderBottom: '2px solid orange', maxHeight: "50px", minHeight: "50px"
          }}
        >
          <Typography style={{ color: "#414c97", margin: "0px" }}><b>Activity History</b></Typography>
        </AccordionSummary>

        <AccordionDetails style={{ backgroundColor: '#F4F7FC', padding: '15px' }}>
          <Typography component={'div'} >
            <div className=''>
              <div className="row m-1 ">
                {allActivityData?.length > 0 ? allActivityData?.map(item => (
                  <div className='col-4'>
                    <div className='mx-5 my-3 shadow' style={{ cursor: 'pointer' }} onClick={() => { localStorage.setItem("chosedREport", item?.id); getSingleReportData(item?.id) }}>

                      <div className="row px-2 subbject_row">
                        <div>
                          {item?.activitySubject ? item.activitySubject : '-'}
                        </div>
                      </div>

                      <div className="row p-1" style={{ backgroundColor: 'white', borderRadius: '0 0 5px 5px ' }}>
                        <div className="row">
                          <div className="col">
                            <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>
                              <span style={{ fontSize: '13px' }}>Create Date</span>
                              <br />
                              <b>
                                {item?.createdAt ? formatDate(item?.createdAt) : '-'}
                              </b>
                            </p>
                          </div>

                          <div className="d-flex col">
                            <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>
                              <span style={{ fontSize: '13px', paddingRight: "23px" }}>Priority</span> <br />
                              <div className='d-flex justify-content-between'>
                                <b>{item?.priority}</b>
                                <div className="d-flex justify-content-center align-items-center priority_icon">
                                  {item?.priority === 'high' && <i className="fas fa-chevron-up" style={{ color: 'red', fontSize: '23px' }}></i>}
                                  {item?.priority === 'medium' && <><i className="fas fa-equals mx-1" style={{ color: 'orange', fontSize: '23px' }}></i></>}
                                  {item?.priority === 'low' && <i className="fas fa-chevron-down" style={{ color: 'blue', fontSize: '23px' }}></i>}
                                </div>
                              </div>
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>
                              <span style={{ fontSize: '13px' }}>Type</span>
                              <br />
                              <b>
                                {item?.activityType ? item?.activityType : '-'}
                              </b>
                            </p>
                          </div>

                          <div className="col">
                            <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>
                              <span style={{ fontSize: '13px' }}>Status</span>
                              <br />
                              <b>
                                {item?.status ? item?.status : '-'}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="d-flex col justify-content-start ">
                            <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>
                              <span style={{ fontSize: '13px', paddingRight: "23px" }}>Created By</span> <br />
                              <b>{item?.createdBy ? (
                                allAdminInfo?.filter((data) => data?.email === item?.createdBy)?.[0]?.name || '-'
                              ) : (
                                '-'
                              )}</b>
                            </p>
                          </div>

                          <div className="d-flex col justify-content-start ">
                            <p className='p-1 m-0' style={{ fontSize: '15px', color: '#656A87' }}>
                              <span style={{ fontSize: '13px', paddingRight: "23px" }}>Discuss With</span> <br />
                              <b>{item?.disscussTo ? (
                                allAdminInfo?.filter((data) => data?.userId === item?.disscussTo)?.[0]?.name || '-'
                              ) : (
                                '-'
                              )}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )) :
                  <div className='d-flex justify-content-center'>
                    <h2 style={{ color: '#5A607F' }}>No Reports Found</h2>
                  </div>}
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <AddActivityModal show={addModalShow} allAdminInfo={allAdminInfo} onHide={() => setAddModalShow(false)} />
      <UpdateActivityModal show={updateModalShow} allAdminInfo={allAdminInfo} reportData={reportData1} onHideUpdate={() => setUpdateModalShow(false)} />
    </>
  );
}



const mapDispatchToProps = (dispatch) => {
  return {
    accountAction4: (config, navigate, is_reciptBtn, setLoading) =>
      dispatch(accountAction4(config, navigate, is_reciptBtn, setLoading)),
    updateActivityStatus: (config, navigate) => dispatch(updateActivityStatus(config, navigate)),
  };
};

//Connecting the component to our store
export default connect(null, mapDispatchToProps)(StudentActivities);
