
import React,{useRef,useState} from 'react';
import {jsPDF} from "jspdf";
import QRCode from 'qrcode';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import ITEG_BG from '../images/ITEG_S2.jpg';
import BEG_BG from '../images/BEG_S.jpg'
import MEG_BG from '../images/MEG_S.jpg'
import dropIcon from '../assets/drag-and-drop.png'
import {DownloadPdfCertificate} from './DownloadPdfCertificate';
import {capitalizeFirstLetter} from '../utils/capitalizeFirstLetter';
import {CertificateExcelDownload} from '../downloadExcelFille/CertificateExcel';
import {StudentIdCardExcelDownload} from '../downloadExcelFille/StudentIdCardExcel';
import {ShowError} from '../certificate/CertificateForm/showError/ShowError';
export const StudentCardAndCertificateAsZip = () => {
    const inputFile = useRef(null);
    const [error,setError] = useState();
    const [selectedFile,setSelectedFile] = useState(null);
    const [selectedType,setSelectedType] = useState('certificate');

    const handleFileChange = async (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        if(inputFile.current) {
            inputFile.current.value = '';
            inputFile.current.type = 'text';
            inputFile.current.type = 'file';
        }
    };


    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];
        setSelectedFile(file);

    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };


    const generateQR = async text => {
        try {
            const opts = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 1,
                margin: 0,
                color: {
                    dark: "#000000",
                }
            }
            return await QRCode.toDataURL(JSON.stringify(text),opts);
        } catch(err) {
            console.error(err)
            return null;
        }
    }

    const findKeyIgnoreCase = (obj,keyName) => {
        const lowerKeyName = keyName.toLowerCase();
        for(const key in obj) {
            if(key.toLowerCase() === lowerKeyName) {
                return key;
            }
        }
        return null;
    };

    const generatePDFs = async (excelData) => {
        const zip = new JSZip();
        for(let i = 0; i < excelData.length; i++) {
            const entry = excelData[i];
            const QR = await generateQR(entry.adhaar);
            if(QR) {
                const pdf = await generatePDF(QR,entry);
                zip.file(`${entry.name}-${entry.group}.pdf`,pdf);
            }
        }
        zip.generateAsync({type: 'blob'}).then((content) => {
            const date = new Date();
            saveAs(content,`student_cards-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.zip`);
        });
    };

    const generatePDF = async (QR,data) => {
        const doc = new jsPDF('l','mm',[84,54]);
        if(data.group.toLowerCase() === 'iteg') {
            doc.addImage(ITEG_BG,'jpeg',0,0,84,54);
            doc.addImage(QR,'png',64,28.5,16.5,16.5);

        } else if(data.group.toLowerCase() === 'beg') {
            doc.addImage(BEG_BG,'jpeg',0,0,84,54);
            doc.addImage(QR,'png',63.5,29.2,16.5,16.5);
        } else if(data.group.toLowerCase() === 'meg') {
            doc.addImage(MEG_BG,'jpeg',0,0,84,54);
            doc.addImage(QR,'png',63.5,29.2,16.5,16.5);

        }
        doc.setFont(undefined,'bold');
        doc.setFontSize(8);
        doc.text(data.session,45,15);
        doc.setFontSize(7);
        doc.text(capitalizeFirstLetter(data.name),45,19.5);
        doc.text(capitalizeFirstLetter(data.fatherName),45,24);
        doc.text(data.branch.toUpperCase(),45,28.5);
        doc.text(data.mobileNumber.toString(),45,32.5);
        doc.addImage(data.photoData,'jpeg',4,14.5,17.5,21.55);
        return doc.output('blob');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!selectedFile) {
            alert("Please upload an Excel file");
            return;
        }

        if(selectedType === 'id-card') {
            const reader = new FileReader();
            reader.onload = async function(e) {
                // const binaryString = e.target.result;
                // const workbook = XLSX.read(binaryString,{type: 'binary'});
                // const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
                // const worksheet = workbook.Sheets[sheetName];
                // const excelData = XLSX.utils.sheet_to_json(worksheet);
                const data = await selectedFile.arrayBuffer(selectedFile);
                const excelFile = XLSX.read(data);
                const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]];
                let excelData = XLSX.utils.sheet_to_json(excelSheet);
                console.log("Excle data is ",excelData)
                if(!excelData[0][findKeyIgnoreCase(excelData[0],"Name")] || !excelData[0][findKeyIgnoreCase(excelData[0],"session")] || !excelData[0][findKeyIgnoreCase(excelData[0],"branch")] || !excelData[0][findKeyIgnoreCase(excelData[0],"photoData")] || !excelData[0][findKeyIgnoreCase(excelData[0],"mobileNumber")] || !excelData[0][findKeyIgnoreCase(excelData[0],"Adhaar")] || !excelData[0][findKeyIgnoreCase(excelData[0],"Group")] || !excelData[0][findKeyIgnoreCase(excelData[0],"fatherName")]) {
                    setError("File must Contain Name of Columns Name , fatherName , Session , Branch , PhotoData , MobileNumber , Adhaar , Group.  Note:- if you have column please check  spelling mistake  and don't give blank space between rows.");
                    return;
                }
                // Map column names to expected keys
                const mappedData = excelData.map((entry) => {
                    return {
                        name: entry[findKeyIgnoreCase(entry,'Name')],
                        fatherName: entry[findKeyIgnoreCase(entry,'FatherName')],
                        session: entry[findKeyIgnoreCase(entry,'Session')],
                        branch: entry[findKeyIgnoreCase(entry,'Branch')],
                        photoData: entry[findKeyIgnoreCase(entry,'PhotoData')],
                        mobileNumber: entry[findKeyIgnoreCase(entry,'MobileNumber')],
                        adhaar: entry[findKeyIgnoreCase(entry,'Adhaar')],
                        group: entry[findKeyIgnoreCase(entry,'Group')]
                    };
                });

                await generatePDFs(mappedData);
            };
            reader.readAsBinaryString(selectedFile);
        } else {
            const error = await DownloadPdfCertificate(selectedFile);
            if(typeof error[0] === 'string') {
                setError(error);
                console.log("Error is ",error)
                return;
            } else {
                console.log('error is  not ',error)
            }
        }

    }

    const handleSampleExcel = () => {
        if(selectedType === 'id-card') {
            StudentIdCardExcelDownload()
        }
        if(selectedType === 'certificate') {
            CertificateExcelDownload();
        }
    };
    return (
        <div
            className="container-form me-3 d-flex flex-column justify-content-center align-items-center bg-transparent"

        >
            <div className='  shadow-sm px-3 rounded-3  certificate-container mt-3 mt-lg-0 bg-white' style={{minWidth: '500px',minHeight: "90%"}}>
                <div> <h5 className=' text-center my-3'> Student Certificate & Card</h5></div>
                <div className="mb-4">
                    <label htmlFor="exampleFormControlInput1" className=" addStdLable">Type</label>

                <select className="form-select" name='type' required onChange={(e) => {setSelectedType(e.target.value); console.log("type is ",e.target.value)}} aria-label="Default select example">

                    <option value="certificate" selected>Certificate</option>
                    <option value="id-card" > Id Card</option>

                </select>
            </div>

                <div className="file-field-container my-3 d-flex flex-column justify-content-center align-items-center   my-1 my-md-2 w-auto text-center rounded-3  py-1 position-relative  form-control "
                onDrop={handleDrop}
                style={{height: "40%"}}
                onDragOver={handleDragOver}
            >
                <img src={dropIcon} style={{width: "100px"}} height={"50%"} alt="" className=' w-25 mt-md-4' />

                <label htmlFor="files" className="cursor-pointer d-flex flex-column  justify-content-end  align-items-center  w-100 h-100">
                    <span>  Upload student name excel list </span>
                    <span className=' fw-bold fs-6 '>or drop it here</span>
                </label>

                <input
                    id="files"
                    className='file-field '
                    style={{visibility: 'hidden',width: '1px',height: '1px'}}
                    name="excelFile"
                    ref={inputFile}
                    accept=".xlsx, .xls, .xlsm, .xlsb, .csv, .xlam, .xltx, .xltm"
                    onChange={handleFileChange}
                    type="file"
                />
            </div>
            <div className="my-0 d-flex justify-content-center">
                {selectedFile ? (
                    <div style={{display: 'flex',alignItems: 'center'}}>
                        <span>{selectedFile.name}</span>
                        <button
                            type="button"
                            onClick={handleRemoveFile}
                            className="fw-bold cursor-pointer ms-2 px-1 border-0 text-danger rounded-circle"
                            title="remove file"
                        >
                            &#x2715;
                        </button>
                    </div>
                ) : null}
            </div>
            <div className=' d-flex justify-content-between'>

                <button className='btn btn-outline-dark  d-block my-3' onClick={handleSampleExcel}> Sample Excel</button>
                <button className='btn btn-outline-dark active d-block  my-3' onClick={handleSubmit}> Submit</button>
            </div>
            {error?.length ? <ShowError type={"Invalid File Data"} modalShow={error?.length} onClick={() => {handleRemoveFile(); setError('');}} error={error} /> : null}
            </div>
        </div>
    );
};
