import React,{useState} from 'react';
import {NavLink} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {Outlet} from 'react-router-dom';
import {isSuperAdmin} from '../../../helpers/SuperAdmin';
import {isStudentAdmin} from '../../../helpers/StudentAdmin';

export const CertificatesAndCardRoutes = () => {
  const [active,setActive] = useState("student-id-card");
  return (
    <>
      <Box sx={{flexGrow: 1,pr: 2}}>
        <AppBar position="static" sx={{backgroundColor: "#e7f1ff",color: "inherit !important",width: '100%',boxShadow: 'none'}}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{mr: 2}}
            ></IconButton>

            <Button color="inherit">
              <NavLink
                to={isSuperAdmin() ? `/admin_dashboard/cards-certificate` : isStudentAdmin() ? `/student_admin_dashboard/cards-certificate` : null}
                className={`nav-link ${active === 'student-id-card' ? ' bg-dark-subtle' : null}`}
                style={{color: '#0c63e4',textDecoration: 'none',backgroundColor: active === 'student-id-card' ? 'rgba(0, 0, 0, 0.06)' : null}}
                onClick={() => setActive('student-id-card')}

              >
                Student Id Card
              </NavLink>
            </Button>
            <Button color="inherit">
              <NavLink
                to={isSuperAdmin() ? `/admin_dashboard/cards-certificate/teacher` : isStudentAdmin() ? `/student_admin_dashboard/cards-certificate/teacher` : null}
                // to="/admin_dashboard/cards-certificate/teacher"
                className={`nav-link ${active === 'teachers-card' ? ' bg-dark-subtle' : null}`}
                style={{color: '#0c63e4',textDecoration: 'none',backgroundColor: active === 'teachers-card' ? 'rgba(0, 0, 0, 0.06)' : null}}
                onClick={() => setActive('teachers-card')}
              >
                TEACHERS
              </NavLink>
            </Button>
            <Button color="inherit">
              <NavLink
                to={isSuperAdmin() ? `/admin_dashboard/cards-certificate/certificate` : isStudentAdmin() ? `/student_admin_dashboard/cards-certificate/certificate` : null}
                // to="/admin_dashboard/cards-certificate/certificate"
                className={`nav-link ${active === 'level-certificate' ? ' bg-dark-subtle' : null}`}
                style={{color: '#0c63e4',textDecoration: 'none',backgroundColor: active === 'level-certificate' ? 'rgba(0, 0, 0, 0.06)' : null}}
                onClick={() => setActive('level-certificate')}
              >
                LEVEL CERTIFICATE
              </NavLink>
            </Button>

            <Button color="inherit">
              <NavLink
                to={isSuperAdmin() ? `/admin_dashboard/cards-certificate/student-id-card-certificate-zip` : isStudentAdmin() ? `/student_admin_dashboard/cards-certificate/student-id-card-certificate-zip` : null}

                // to="/admin_dashboard/cards-certificate/student-id-card-certificate-zip"
                className={`nav-link ${active === 'certificate-id-card-zip' ? ' bg-dark-subtle' : null}`}
                style={{color: '#0c63e4',textDecoration: 'none',backgroundColor: active === 'certificate-id-card-zip' ? 'rgba(0, 0, 0, 0.06)' : null}}
                onClick={() => setActive('certificate-id-card-zip')}
              >
                Certificate & ID card As Zip
              </NavLink>
            </Button>
            <Button color="inherit">
              <NavLink
                to={isSuperAdmin() ? `/admin_dashboard/cards-certificate/teacher-card-zip` : isStudentAdmin() ? `/student_admin_dashboard/cards-certificate/teacher-card-zip` : null}

                // to="/admin_dashboard/cards-certificate/teacher-card-zip"
                className={`nav-link ${active === 'teachers-id-card-zip' ? ' bg-dark-subtle' : null}`}
                style={{color: '#0c63e4',textDecoration: 'none',backgroundColor: active === 'teachers-id-card-zip' ? 'rgba(0, 0, 0, 0.06)' : null}}
                onClick={() => setActive('teachers-id-card-zip')}
              >
                teacher card As Zip
              </NavLink>
            </Button>
          </Toolbar>
        </AppBar>
      </Box>

      <Outlet />
    </>
  );
}
