import {saveAs} from 'file-saver';
import {PDFDocument,rgb} from 'pdf-lib';
import level1 from '../assets/iteg-certificate/Certificates Testing.pdf'
import level2 from '../assets/iteg-certificate/Certificates Testing.pdf'
import level3 from '../assets/iteg-certificate/Certificates Testing.pdf'
import {capitalizeFirstLetter} from './capitalizeFirstLetter';
import fontkit from '@pdf-lib/fontkit'
import fontFamily from '../assets/Roboto-Medium.ttf'
export const DownloadPdf = (level,name,setName,group) => {

    const generatePDF = async (name) => {
        try {
            let file;
            console.log("level is ",level)
            if(level.toLowerCase() === "a" || level.toLowerCase() === "1a" || level.toLowerCase() === "1b" || level.toLowerCase() === "1c") {
                file = level1;
            } else if(level.toLowerCase() === "b" || level.toLowerCase() === "2a" || level.toLowerCase() === "2b" || level.toLowerCase() === "2c") {
                file = level2;
            } else if(level.toLowerCase() === "c" || level.toLowerCase() === "3a" || level.toLowerCase() === "3b" || level.toLowerCase() === "3c") {
                file = level3;
            }
            if(!group?.toLowerCase() === 'iteg' && !group?.toLowerCase() === 'meg' && !group?.toLowerCase() === 'beg') {
                console.log("No group found ",group);
                return;
            }


            const existingPdfBytes = await fetch(file).then((res) =>
                res.arrayBuffer()
            );
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const fontBytes = await fetch(fontFamily).then(res => res.arrayBuffer());
            pdfDoc.registerFontkit(fontkit);
            const customFont = await pdfDoc.embedFont(fontBytes);

            const pages = pdfDoc.getPages();
            const firstPage = pages[0];


            const textWidth = customFont.widthOfTextAtSize(name,30);

            const middleCharacterIndex = Math.floor(name.length / 2);
            const middleCharacterWidth = customFont.widthOfTextAtSize(name[middleCharacterIndex],30);
            const x = (firstPage.getSize().width - textWidth) / 2 + middleCharacterWidth / 2;
            const y = firstPage.getSize().height / 2; // y-coordinate of the text position
            console.log("X center is ",(firstPage.getSize().width / 2) + 94);
            console.log("Y position is ",y - 50);

            firstPage.drawText(capitalizeFirstLetter(name),{
                x: x + 100,
                y: y - 50,
                size: 30,
                color: rgb(0,0,0),
                font: customFont
            });
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes],{type: "application/pdf"});

            saveAs(blob,`${name}-${level}.pdf`);
            if(setName) {
                setName("");
            }
        } catch(error) {
            console.error("Error generating PDF:",error);
        }
    };


    const submitHandler = async () => {
        const val = capitalizeFirstLetter(name.trim());
        console.log("value of val capitalize is",val)
        if(val !== "") {
            if(group?.toLowerCase() === 'iteg') {

                await generatePDF(val);
            } else if(group?.toLowerCase() === 'beg') {
                console.log("NO Certificate present of beg ");
                return;
            } else if(group?.toLowerCase() === 'meg') {
                console.log("NO Certificate present of meg ");
                return;
            }
        } else {
            console.log("not a valid data name ");
        }
    };
    submitHandler();
}
