import {useEffect,useRef,useState} from 'react'
import {ExcelDataReader} from '../../utils/ExcelDataReader';

import dropIcon from '../../assets/drag-and-drop.png'
import {ShowError} from './showError/ShowError';
import {ShowPdf} from './showCertificate/ShowPdf';
import './Certificate.css'
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import {DownloadPdf} from '../../utils/Download';
export const Certificate = () => {
    const [dataName,setDataName] = useState("");
    const [showDemo,setShowDemo] = useState(false);
    const [submitClick,setSubmitClick] = useState(false);
    const [showError,setShowError] = useState(false);
    const [selectedFile,setSelectedFile] = useState(null);
    const [excel,setExcel] = useState([]);
    const [name,setName] = useState('');
    const [group,setGroup] = useState('iteg');
    const [level,setLevel] = useState('level-1a');
    const [ButtonClickError,setButtonClickError] = useState('');
    const levelsName = [{
        label: 'Level A',
        options: [{name: 'Level-1A',value: "1A"},{name: 'Level-1B',value: "1B"},{name: 'Level-1C',value: "1C"}]
    },{
        label: 'Level B',
        options: [{name: 'Level-2A',value: "2A"},{name: 'Level-2B',value: "2B"},{name: 'Level-2C',value: "2C"}]
    },{
        label: 'Level C',
        options: [{name: 'Level-3A',value: "3A"},{name: 'Level-3B',value: "3B"},{name: 'Level-3C',value: "3C"}]
    },];
    const inputFile = useRef(null);
    useEffect(() => {console.log("level is ",level)},[level]);
    const handleFileChange = async (event) => {
        setSelectedFile(event.target.files[0]);
        let arr = await ExcelDataReader(event.target.files[0]);
        if(typeof arr[0] === 'string') {
            console.log("type is string")
            setExcel(arr);
            handleErrorShow();
        } else {
            arr ? setExcel([...arr]) : setExcel([]);
            handleErrorRemove();
        }
        console.log("now excel is ",arr);
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setShowError(false);
        if(inputFile.current) {
            inputFile.current.value = '';
            inputFile.current.type = 'text';
            inputFile.current.type = 'file';
        }
        setExcel([]);
    };


    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if(dataName === "") {
            const file = event.dataTransfer.files[0];
            setSelectedFile(file);
            let arr = await ExcelDataReader(file);

            if(typeof arr[0] === 'string') {
                console.log("type is string ");
                setExcel(arr);
                handleErrorShow();
            } else {
                arr ? setExcel([...arr]) : setExcel([]);
                handleErrorRemove();
            }
            console.log("now excel is ",arr);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleErrorShow = () => setShowError(true);
    const handleErrorRemove = () => setShowError(false);

    useEffect(() => {
        if(excel.length && submitClick && level !== "") {
            excel.forEach((value) => {
                DownloadPdf(level,value.name,null,group);
            });
            setSubmitClick(false);
            handleRemoveFile();
            setLevel("");
        }
    },[excel,level,submitClick,group]);

    useEffect(() => {
        if(name?.length && submitClick && level !== "") {
            DownloadPdf(level,name,setName,group);
            setSubmitClick(false);
            setLevel("");
        }
    },[name,level,submitClick,group])
    return (
        < >
            <div
                className="container-form me-3 d-flex flex-column justify-content-center align-items-center bg-transparent "
            >
                <div className='  shadow-sm px-3 rounded-3  certificate-container mt-3 mt-lg-0 bg-white' style={{minWidth: '500px',minHeight: "90%"}}>
                    <div> <h5 className=' text-center my-3'> Generate Certificate</h5></div>

                    <div className=' mb-3'>
                        <label htmlFor="name" className='addStdLable'>Name</label>
                        <input
                            type="text"
                            value={dataName}
                            disabled={excel.length ? true : false}
                            onChange={(e) =>
                                setDataName(e.target.value)}
                            className=" form-control "
                            name="name"
                            placeholder='Student Name'
                            id="name"
                        />
                    </div>

                    <div className=' mb-3'>
                        <label htmlFor="name" className='addStdLable'>Level</label>
                        <select
                            type="text"
                            value={level}
                            onChange={(e) =>
                                setLevel(e.target.value)}
                            className=" form-select "
                            name="name"
                            id="name"
                        >
                            <option value="">Select Level</option>
                            {levelsName.map((ele,i) => {
                                return (
                                    <optgroup className=' fw-medium' key={i} label={ele.label}>
                                        {ele.options.map((opt,index) => {
                                            return <option key={index} value={opt.value}>{opt.name}</option>
                                        })}
                                    </optgroup>
                                )
                            })}
                        </select>
                    </div>
                    <div className=' mb-3'>
                        <label htmlFor="group" className='addStdLable'>Group</label>
                        <select
                            type="text"
                            value={group}
                            onChange={(e) =>
                                setGroup(e.target.value)}
                            className=" form-select "
                            name="group"
                            id="name"
                        >
                            <option value="iteg">Iteg</option>
                            <option value="meg">Meg</option>
                            <option value="beg">Beg</option>

                        </select>
                    </div>
                    <div className="text-center text-secondary">or</div>

                    <div className="file-field-container my-3 d-flex flex-column justify-content-center align-items-center    my-1 my-md-2 w-auto text-center rounded-3  py-1 position-relative  form-control "
                        onDrop={handleDrop}
                        style={{height: "40%"}}
                        onDragOver={handleDragOver}
                    >
                        <img src={dropIcon} style={{width: "100px"}} height={"50%"} alt="" className=' w-25 mt-md-4' />

                        <label htmlFor="files" className="cursor-pointer d-flex flex-column  justify-content-end  align-items-center  w-100 h-100">
                            <span>  Upload student name excel list </span>
                            <span className=' fw-bold fs-6 '>or drop it here</span>
                        </label>

                        <input
                            id="files"
                            className='file-field '
                            style={{visibility: 'hidden',width: '1px',height: '1px'}}
                            name="excelFile"
                            disabled={dataName !== "" ? true : false}
                            ref={inputFile}
                            accept=".xlsx, .xls, .xlsm, .xlsb, .csv, .xlam, .xltx, .xltm"
                            onChange={handleFileChange}
                            type="file"
                        />
                    </div>
                    <div className="my-0 d-flex justify-content-center">
                        {selectedFile ? (
                            <div style={{display: 'flex',alignItems: 'center'}}>
                                <span>{selectedFile.name}</span>
                                <button
                                    type="button"
                                    onClick={handleRemoveFile}
                                    className="fw-bold cursor-pointer ms-2 px-1 border-0 text-danger rounded-circle"
                                    title="remove file"
                                >
                                    &#x2715;
                                </button>
                            </div>
                        ) : null}
                    </div>
                    <div className=' py-3 d-flex  justify-content-between'>
                        <button className=' btn btn-outline-dark' onClick={() => {
                            if(dataName.length && level !== "") {
                                setShowDemo(true);
                            } else {
                                setButtonClickError("Please Select  Level of Certification and Name of Student Before Preview")
                            }
                        }}> Preview</button>
                        <button
                            className=' btn btn-outline-dark active'
                            onClick={() => {
                                if((dataName.length || excel[0]) && level !== "") {
                                    setName(capitalizeFirstLetter(dataName));
                                    setSubmitClick(true);
                                    setDataName("")
                                } else {
                                    setButtonClickError("Please Select  Level of Certification and Name or excel  file of students Before Submit");
                                }
                            }}
                        >
                            Submit
                        </button>

                    </div>

                    {showDemo && level !== "" && dataName?.length ? <ShowPdf show={showDemo} group={group} name={dataName} level={level} setShow={setShowDemo} /> : null}
                    {ButtonClickError?.length > 0 ? <ShowError type={"Invalid Input Data"} error={ButtonClickError} modalShow={ButtonClickError.length > 0} onClick={() => {setButtonClickError("")}} /> : null}
                    {showError ? <ShowError type={"Invalid File"} error={excel} modalShow={showError} setSelectedFile={setSelectedFile} setModalShow={setShowError} onClick={handleRemoveFile} /> : null}
                </div>
            </div>
        </>
    )
}
