import React,{useEffect} from "react";
import { Fragment, useMemo } from "react";
import Avatar from '@mui/material/Avatar';
import {
    useTable,
    useFilters,
    useSortBy,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useAsyncDebounce,
} from "react-table";
import { TableCheckbox } from "../tableComponents/TableCheckbox";
import rightArrow from '../../assests/image/right_arrow_icon.svg'
import AvatarImg from '../../assests/image/Avtar.jpeg'
import {
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CPopover,
} from "@coreui/react";
import filtericon from "../../assests/image/AccountIcons/filter.svg";
import updown_sort from '../../assests/image/updown_sort.svg';
import "rsuite/dist/rsuite.min.css";
import AllUrl from '../../../redux/constants/url';
import { connect } from 'react-redux';
import { fetchStudentAccountData, changeDailyReport, accountAction } from '../../../redux/actionDispatcher/superAdmin/studentAccountTableDataDispatcher';
import SkeletonColor from '../../../helpers/Skeletrone';
import Pagination from "../../assests/common/Pagination";
import Loader from "../../assests/common/Loader";
import NoDataFound from "../../assests/common/NoDataFound";
import { CSVLink } from "react-csv";
import { Tooltip, Whisper } from "rsuite";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import allUrls from '../../../redux/constants/url';
import {DownloadCertificatePdfAsZip} from "../cards-certificate/certificate_card_zip_without_excel/DownloadCertificatePdfsAsZip";
import {DownloadStudentIdCardAsZip} from "../cards-certificate/certificate_card_zip_without_excel/DownloadStudentIdCardAsZip";
export const MultipleFilter = (rows, accessor, filterValue) => {
    const arr = [];
    rows.forEach((val) => {
        if (filterValue.includes(val.original[accessor])) arr.push(val);
    });
    return arr;
};

function setFilteredParams(filterArr, val) {
    if (filterArr.includes(val)) {
        filterArr = filterArr.filter((n) => {
            return n !== val;
        });
    } else filterArr.push(val);

    if (filterArr.length === 0) filterArr = undefined;
    return filterArr;
}



function SelectColumnFilter({
    column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    let offsetObj = [0, 0];

    if (id === 'branch') offsetObj = [190, 18]
    if (id === 'joinBatch') offsetObj = [150, 18]
    if (id === 'year') offsetObj = [40, 18]
    if (id === 'ssismGroup') offsetObj = [40, 18]



    let name = id;

    switch (id) {
        case 'branch':
            name = 'Select Branch';
            break;
        case 'joinBatch':
            name = 'Select Join Year';
            break;
        case 'year':
            name = 'Select Year';
            break;
        case 'ssismGroup':
            name = 'Select Group';
            break;
        default:
            break;
    }

    return (
        <Fragment>
            <div onClick={(e) => { e.preventDefault() }} className="d-flex justify-content-end">
                <CPopover
                    offset={offsetObj}
                    content={
                        <div className="">
                            {options.map((option, i) => {
                                let option_label = option;

                                if (id === 'is_active') {
                                    if (option === 'true')
                                        option_label = 'Active'
                                    else
                                        option_label = 'Deactive'
                                }

                                return (
                                    <Fragment key={i}>
                                        <div id={`${id}`} style={{ height: '30px', cursor: 'pointer' }} className="filter_btn_hover p-1 pt-2 my-1 d-flex align-items-center ">
                                            <label
                                                onClick={(e) => { e.stopPropagation() }}
                                                className="font-medium text-gray-700 d-flex align-items-center cursor-pointer"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <input
                                                    checked={filterValue.includes(option)}
                                                    type="checkbox"
                                                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-1"
                                                    id={option}
                                                    name={option}
                                                    value={option}
                                                    style={{ cursor: 'pointer' }}
                                                    onChange={(e) => {
                                                        setFilter(
                                                            setFilteredParams(filterValue, e.target.value)
                                                        );
                                                    }}
                                                    onClick={(e) => { e.stopPropagation() }}
                                                >

                                                </input>
                                                {
                                                    option_label
                                                }
                                            </label>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                    }
                    placement="right"

                >

                    <div className="btn-group filter_btn_hover">
                        {
                            <>
                                <button
                                    onClick={(e) => { e.preventDefault() }}
                                    className="btn filter_btn"
                                >
                                    {name}
                                </button>
                                <img src={rightArrow} alt=">" width="6px" style={{
                                    marginTop: "4px",
                                    marginRight: '10px'
                                }} />
                            </>
                        }
                    </div>
                </CPopover>
            </div >
        </Fragment >
    );
}


// Define a default UI for filtering
function GlobalFilter({ filter, setFilter, preGlobalFilteredRows }) {
    const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
    }, 1);

    return (
        <>
            <input
                style={{
                    width: "270px",
                    height: "41px",
                    outline: "none",
                    border: "1px solid #7979792b",
                    padding: "5px",
                    borderRadius: "4px",
                    paddingLeft: '12px'
                }}
                type="search"
                value={filter || ""}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                placeholder="Search..."
            />
            <i
                style={{ marginLeft: "-31px", alignSelf: 'center', marginBottom: '7px', color: "rgb(90, 96, 127,0.7)" }}
                className="fas fa-search"
            ></i>
        </>
    );
}

function StudentTableFirst({ fetchUsers, studentData, accountAction }) {
    const defaultLevel = '1a';
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    React.useEffect(() => {
        var config = {
            method: "GET",
            url: AllUrl.allActiveStudent,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        fetchUsers(config);
    }, []);


    const getAllInfoOfStudent = (original, is_reciptBtn) => {
        setLoading(true)
        let data = JSON.stringify({
            "stdId": original.stdId,
            "choice": 1
        });
        localStorage.setItem('choice', 1)
        let config = {
            method: 'post',
            url: allUrls.allInfoOfActiveStudent,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        accountAction(config, navigate, is_reciptBtn, setLoading);
    }

    const columns = React.useMemo(
        () => [
            {
                header: "S. No",
                accessor: "S.N",
                Cell: ({ row: { original, index } }) => {
                    return (index + 1)
                },
                Filter: "",
                filter: "",
            },
            {
                header: "Profile",
                accessor: "photo",

                Cell: ({ row: { original, index } }) =>
                (
                    <div className="d-flex m-0 flex-column justify-content-start">
                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                            <Tooltip>
                                View Reports .
                            </Tooltip>
                        }>
                            {original.photo ?
                                <Avatar src={original.photo.includes(`PHOTO_${original.stdId}`) ? original.photo : `  ${original.photo.replace('documents/', 'profile').replace(original.stdId, '')}`}
                                    alt="profile"

                                    style={{ cursor: "pointer", borderRadius: '50%', width: "50px", height: "50px" }}
                                    onClick={() => {
                                        getAllInfoOfStudent(original, false);
                                    }}
                                    className="mx-auto"

                                >{original.name?.slice(0, 1)}
                                </Avatar>
                                :
                                <img
                                    alt="profile"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        getAllInfoOfStudent(original, false)
                                    }}
                                    className="mx-auto"
                                    src={AvatarImg}
                                    width={50}

                                    text="Image"
                                />}
                        </Whisper>
                    </div >
                ),
                Filter: "",
                filter: "",
            },
            {
                header: "Name",
                accessor: "firstName",
                Cell: ({ row: { original, index } }) => {
                    return (
                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                            <Tooltip>
                                View Reports .
                            </Tooltip>
                        }>
                            <p style={{ cursor: "pointer" }}
                                onClick={() => {
                                    getAllInfoOfStudent(original, false)
                                }}>
                                {original.firstName + " " + original.lastName}
                            </p>
                        </Whisper>
                    )
                },
                Filter: "",
                filter: ""
            },
            {
                header: "Father's Name",
                accessor: "fathersName",
                Filter: "",
                filter: ""
            },
            {
                header: "Stream",
                accessor: "branch",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
            },
            {
                header: "Group",
                accessor: "ssismGroup",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
            },
            {
                header: "Year",
                accessor: "year",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
            },
            {
                header: "Join Batch",
                accessor: "joinBatch",
                Cell: ({ row: { original, index } }) => {
                    return (
                        <p

                        >
                            {original.joinBatch.toString()}

                        </p>
                    )
                },
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
            },
            {
                header: "Village",
                accessor: "village",
                Filter: "",
                filter: ""
            },
            {
                header: "Mobile No",
                accessor: "mobile",
                Filter: "",
                filter: "",
            },
            {
                header: " Father Mobile No",
                accessor: "fatherContactNumber",
                Filter: "",
                filter: "",
            },

            {
                header: "Status",
                accessor: "status",
                Cell: ({ row: { original, index } }) => {
                    return (
                        <p style={{ marginTop: "3px", marginBottom: "3px", fontWeight: "600" }}>
                            {original.status === "false" ? "Deactive" : "Active"}
                        </p>
                    )
                },
                Filter: "",
                filter: ""
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        nextPage,
        previousPage,
        canPreviousPage,
        pageCount,
        gotoPage,
        canNextPage,
        setPageSize,
        pageOptions,
        state,
        setGlobalFilter,
        preGlobalFilteredRows,
        selectedFlatRows,
        prepareRow,
    } = useTable(
        { columns, data: studentData.table_data },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: "selection",
                        header: ({ getToggleAllRowsSelectedProps }) => (
                            <TableCheckbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <TableCheckbox {...row.getToggleRowSelectedProps()} />
                        ),
                    },
                    ...columns,
                ];
            });
        }
    );

    const { globalFilter, pageSize, pageIndex, } = state;

    var idData = [];
    var exportCsv = [];
    var levelStudentsData = [];
    var cardsStudentsData = [];

    const checkboxData = JSON.stringify(
        {
            selectedFlatRows: selectedFlatRows.forEach((row) => {
                let data = Object.assign({}, row.original);
                console.log("photo is ",data.photo)     
                idData.push(data.id);
                levelStudentsData.push({name: `${data.firstName} ${data.lastName}`,group: data.ssismGroup,level: data && data.ssismLevel ? data.ssismLevel : defaultLevel});

                cardsStudentsData.push({
                    name: `${data.firstName} ${data.lastName}`,
                    fatherName: data.fathersName,
                    session: data.joinBatch + " " + (parseInt(data.joinBatch) + 1),
                    branch: data.branch,
                    mobileNumber: data.mobile,
                    adhaar: data.aadharNo,
                    group: data.ssismGroup,
                    photoData: data.photo
                });
                delete data.photo;
                exportCsv.push(data);
            }),
        },
        null,
        2
    );
    console.log("cards students ",exportCsv);
    return studentData.loading ? (
        <SkeletonColor></SkeletonColor>
    ) :
        (
            <Fragment>
                {studentData.loading && (
                    <Loader />
                )}
                <div className="container-fluid">
                    <div style={{ position: 'sticky', top: '80px', backgroundColor: '#f4f7fc', zIndex: '6', paddingBottom: '20px' }}>
                        <div className="row  mx-0 mt-3" >

                            <div className="d-flex">
                                <div style={{ marginLeft: '-12px' }}>
                                    <select
                                        className="form-select table_select_row_options"
                                        value={pageSize}
                                        onChange={(e) => setPageSize(Number(e.target.value))}
                                    >
                                        {[10, 25, 50, 100].map((pageSize) => (
                                            <option value={pageSize} key={pageSize}>
                                                Show Entries {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="ms-4">
                                    <div className="btn-group  ml-3">
                                        <button
                                            className="btn  btn-sm download-btn dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Download
                                        </button>
                                        <div className="dropdown-menu mt-1" style={{ zIndex: "10" }}>
                                            <div>
                                                <div>
                                                    <CSVLink
                                                        className="dropdown-item"
                                                        style={{ fontWeight: "bold" }}
                                                        data={exportCsv}
                                                    >
                                                        Excel file
                                                    </CSVLink>
                                                </div>{" "}
                                            </div>
                                            <div>
                                                <div>
                                                    <span
                                                        className="dropdown-item cursor-pointer"
                                                        onClick={() => {DownloadCertificatePdfAsZip(levelStudentsData)}}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        level Certificates
                                                    </span>
                                                </div>{" "}
                                            </div>
                                            <div>
                                                <div>
                                                    <span
                                                        className="dropdown-item cursor-pointer"
                                                        onClick={() => {DownloadStudentIdCardAsZip(cardsStudentsData)}}
                                                        style={{fontWeight: "bold"}}
                                                    >
                                                        ID Cards
                                                    </span>
                                                </div>{" "}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex ml-auto me-3">
                                    <div className="d-flex mr-2" style={{ height: '40px', width: '42px', backgroundColor: '#fff', borderRadius: '3px', border: "1px solid #EDEDED" }}>

                                        <CDropdown variant="nav-item" style={{ color: 'white' }}>
                                            <CDropdownToggle
                                                placement="bottom-end"
                                                className="py-0"
                                                caret={false}
                                            >
                                                <img
                                                    src={filtericon}
                                                    alt=""
                                                    style={{
                                                        height: "22px",
                                                        width: "35px",
                                                        marginTop: "-35px",
                                                        marginLeft: "-13px",
                                                    }}
                                                />
                                            </CDropdownToggle>

                                            <CDropdownMenu
                                                component={"div"}
                                                className="pt-0 filter-dropdown-menu"
                                                placement="bottom-end"

                                            >
                                                <div className="p-lg-2 pb-0">
                                                    {headerGroups.map((headerGroup) => (
                                                        <div
                                                            style={{ display: "flex flex-column" }}
                                                            {...headerGroup.getHeaderGroupProps()}
                                                        >
                                                            {headerGroup.headers.map((column, i) => (
                                                                <div
                                                                    key={i}
                                                                    style={{ display: "block", justifyContent: "center" }}
                                                                >
                                                                    {column.canFilter ? column.render("Filter") : null}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </CDropdownMenu>
                                        </CDropdown>

                                    </div>
                                    <GlobalFilter
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        filter={globalFilter}
                                        setFilter={setGlobalFilter}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <table {...getTableProps()} id="customers">

                        <thead style={{ position: 'sticky', top: '135px', width: '100%', backgroundColor: '#f4f7fc', zIndex: '5' }}>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render("header")}
                                            <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <img
                                                            src={updown_sort}
                                                            style={{ marginLeft: "5px" }}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <img
                                                            src={updown_sort}
                                                            style={{ marginLeft: "5px" }}
                                                            alt=""
                                                        />
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                {column.isSorted ? (column.isSortedDesc ? "" : "") : ""}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <NoDataFound rows={rows} />

                    <Pagination data={rows} rows={rows}
                        page={page}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        pageSize={pageSize}
                        canPreviousPage={canPreviousPage}
                        previousPage={previousPage}
                        pageOptions={pageOptions}
                        gotoPage={gotoPage}
                        canNextPage={canNextPage}
                        nextPage={nextPage}
                    />

                </div>

            </Fragment >
        );
}

const mapStateToProps = (state) => {
    return {
        studentData: state.accountStudentTableData,
    };
};

//passing the userData in fetchUsers function and also dispatch method
const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: (data) => dispatch(fetchStudentAccountData(data)),
        backOriginal: () => dispatch(changeDailyReport()),
        accountAction: (config, navigate, is_reciptBtn, setLoading) => dispatch(accountAction(config, navigate, is_reciptBtn, setLoading)),
    };
};

//Connecting the component to our store
export default connect(mapStateToProps, mapDispatchToProps)(StudentTableFirst);
