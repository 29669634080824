import React from 'react'
import allUrls from '../../../redux/constants/url'
import { useFormik } from 'formik'
import NumberFormat from 'react-number-format'
import Select from 'react-select'
import axios from 'axios'
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { accountAction } from '../../../redux/actionDispatcher/superAdmin/studentAccountTableDataDispatcher';
import {isStudentAdmin} from '../../../helpers/StudentAdmin'
// import { useNavigate } from 'react-router-dom';


function UpdateStudentPersonalInfo({ handleClose ,disable}) {
    const defaultLevel = '1a'.toUpperCase();
    const isDisabledForStudentAdmin = isStudentAdmin();   
    const levelsName = [{
        label: 'Level A',
        options: [{name: 'Level-1A',value: "1A"},{name: 'Level-1B',value: "1B"},{name: 'Level-1C',value: "1C"}]
    },{
        label: 'Level B',
        options: [{name: 'Level-2A',value: "2A"},{name: 'Level-2B',value: "2B"},{name: 'Level-2C',value: "2C"}]
    },{
        label: 'Level C',
        options: [{name: 'Level-3A',value: "3A"},{name: 'Level-3B',value: "3B"},{name: 'Level-3C',value: "3C"}]
    },];

    const data = localStorage.getItem('userEdit')
    const StudentProfileData = JSON.parse(data)

    const [branchNames, setBranchNames] = useState([{ subjects: 'loading...', id: 0 }])
    const [villageNames, setVillageNames] = useState([{ label: 'loading...', villageId: 0 }])
    const [trackName, setTrackName] = useState([{ label: 'loading...', villageId: 0 }])

    useEffect(() => {
        const getData = async () => {

            const branchName = await axios(allUrls.branchList)
            if (branchName.status === 200) {

                setBranchNames(branchName.data)
            }
            const trackNames = await axios(allUrls.trackList)
            let newTrackNames = [];
            trackNames.data.forEach((ele) => { newTrackNames.push({ 'label': ele.trackName, 'value': ele.trackName }) })
            setTrackName(newTrackNames);
            // // console.log("branch Name ", branchName.data);

            /////////////////////////
            const villageNamesRes = await axios(allUrls.villageNameList)
            let newVillageName = [];
            villageNamesRes.data.forEach((ele) => { newVillageName.push({ 'label': ele.villageName, 'value': ele.villageName }) })
            // // console.log(newVillageName);
            setVillageNames(newVillageName);
        }
        getData();
    }, []);

    const initialValues = {
        firstName: StudentProfileData ? StudentProfileData.accountInfo.firstName : '',
        lastName: StudentProfileData ? StudentProfileData.accountInfo.lastName : '',
        fatherName: StudentProfileData ? StudentProfileData.accountInfo.fathersName : '',
        contactNumber: StudentProfileData ? StudentProfileData.accountInfo.mobile : '',
        FatherContactNumber: StudentProfileData ? StudentProfileData.accountInfo.fatherContactNumber : '',
        dob: StudentProfileData ? StudentProfileData.accountInfo.dob : '',
        village: StudentProfileData ? StudentProfileData.accountInfo.village : '',
        streamName: StudentProfileData ? StudentProfileData.accountInfo.branch : '',
        aadharNumber: StudentProfileData ? (StudentProfileData.accountInfo.aadharNo).toString().match(/.{4}/g).join(' ') : '',
        EnrollmentNumber: StudentProfileData ? StudentProfileData.accountInfo.enrollmentNo : '',
        group: StudentProfileData ? StudentProfileData.accountInfo.ssismGroup : "",
        email: StudentProfileData ? StudentProfileData.accountInfo.email : "",
        trackName: StudentProfileData ? StudentProfileData.accountInfo.trackName : "",
        category: StudentProfileData ? StudentProfileData.accountInfo.category : "",
        gender: StudentProfileData ? StudentProfileData.accountInfo.gender : "",
        isActive: StudentProfileData ? StudentProfileData.accountInfo.isActive : "",
        isPlaced: StudentProfileData ? StudentProfileData.accountInfo.isPlaced === 'true'? true: false : "",
        isPermissioned: StudentProfileData ? StudentProfileData.accountInfo.isPermissioned === 'true'? true: false  : "",
        ssismLevel: StudentProfileData && StudentProfileData.accountInfo.ssismLevel ? StudentProfileData.accountInfo.ssismLevel : defaultLevel

    }

    const validationSchema = Yup.object({

        firstName: Yup.string().trim().min(3, 'minimum 3 characters required').matches(/[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, 'must be alphabates').required("Required!"),
        lastName: Yup.string().trim().min(3, 'minimum 3 characters required').matches(/[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, 'must be alphabates').required("Required!"),
        fatherName: Yup.string().trim().min(3, 'minimum 3 characters required').matches(/[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/, 'must be alphabates').required("Required!"),
        dob: Yup.string().required("Required!").test('doc_check', 'Minimum age must be 12-14 years', val => val?.slice(0, 4) <= (new Date().getFullYear()) - 13),
        contactNumber: Yup.string().trim().min(10, 'Must be exactly 10 digits').required("Required!"),
        FatherContactNumber: Yup.string().trim().min(10, 'Must be exactly 10 digits').required("Required!"),
        aadharNumber: Yup.string().trim().required("Required!").test('len', 'Must be exactly 12 digits', val => val?.replace('X', '').length === 14),
        village: Yup.string().required("Required!").trim().min(3, 'minimum 3 characters required').matches(/^[a-zA-Z]+$/, 'must be alphabates'),
        // EnrollmentNumber: Yup.string().required("Required!"),
        streamName: Yup.string().required("Required!"),
        ssismLevel: Yup.string().required("Required!"),

        //If the group is required uncomment the line below
        group: Yup.string().required("Required!"),
        // new conditions
        // email: Yup.string().required("Not required"),
        category: Yup.string().required("Required!"),
        gender: Yup.string().required("Required!"),
        trackName: Yup.string().required("Required!"),
        isActive: Yup.string().required("Required!"),
        isPlaced: Yup.bool().required("Required!"),
        isPermissioned: Yup.bool().required("Required!"),
        

    })


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            // console.log(values);
            const UpdatePersonalInfoData = {
                "stdId": StudentProfileData.accountInfo.stdId,
                "firstName": formik.values.firstName,
                "lastName": formik.values.lastName,
                "branch": formik.values.streamName,
                "fathersName": formik.values.fatherName,
                "dob": formik.values.dob,
                "mobile": formik.values.contactNumber,
                "fatherContactNumber": formik.values.FatherContactNumber,
                "aadharNo": formik.values.aadharNumber.split(' ').join(''),
                "village": formik.values.village,
                "enrollmentNo": formik.values.EnrollmentNumber,
                "ssismGroup": formik.values.group,
                "email":formik.values.email,
                "isActive":formik.values.isActive,
                "isPlaced":formik.values.isPlaced,
                "isPermissioned":formik.values.isPermissioned,
                "category":formik.values.category,
                "gender":formik.values.gender,
                "trackName": formik.values.trackName,
                "ssismLevel": formik.values.ssismLevel
            }
            if(disable){
                alert("You can't update this student's information");
                return;
            }
            // console.log(UpdatePersonalInfoData);
            const token = localStorage.getItem("token");
            var config = {
                method: 'post',
                url: allUrls.updatePersonalInformation,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: UpdatePersonalInfoData
            };
            // // console.log(config, UpdatePersonalInfoData)

            const response = await axios(config)
            // console.log(response);
            if (response.status === 200) {
                toast.success('Personal Information Successfully Updated', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(
                    handleClose()
                    , 2000
                )
                let backData = JSON.stringify({
                    "stdId": StudentProfileData.accountInfo.stdId,
                    "choice": parseInt(localStorage.getItem('choice'))
                });
                let getBackData = {
                    method: 'post',
                    url: allUrls.allInfoOfActiveStudent,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    data: backData
                };
                axios(getBackData)
                    .then(function (response) {

                        // console.log(response);
                        if (response.status === 200) {
                            localStorage.setItem('userEdit', JSON.stringify(response.data));
                            window.location.reload();
                        }
                    })
            }
            else if (response.status === 400) {
                toast.warn('Invalid Email', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else if (response.status === 406) {
                toast.warn('User Not Found', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else {
                toast.warn('Internal server error', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            // console.log(response.status);

        },

    });

    return (
        <>

            <div>
                <form onSubmit={formik.handleSubmit}>


                    <div style={{ borderRadius: '5px' }}>

                        <div className='d-flex fw-bold text-light p-2' style={{
                            justifyContent: 'center',
                            backgroundColor: 'orange', width: '100%', margin: 0
                        }}>Edit Personal Detail </div>
                        <div className="d-flex p-1">
                            <div className="row">
                                <div className='row m-1'>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">First Name</label>  <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={isDisabledForStudentAdmin}
                                            value={formik.values.firstName}
                                            name="firstName"
                                            type="text"
                                            className={formik.touched.firstName ? `form-control ${formik.errors.firstName ? "invalid" : ""}` : 'form-control'}
                                            placeholder="First name"
                                        />
                                        {formik.errors.firstName && formik.touched.firstName ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.firstName}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Last Name</label>  <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={isDisabledForStudentAdmin}
                                            value={formik.values.lastName}
                                            name="lastName"
                                            type="text"
                                            className={formik.touched.lastName ? `form-control ${formik.errors.lastName ? "invalid" : ""}` : 'form-control'}
                                            placeholder="Last Name"
                                        />
                                        {formik.errors.lastName && formik.touched.lastName ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.lastName}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Father Name</label>  <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.fatherName}
                                            disabled={isDisabledForStudentAdmin}
                                            name="fatherName"
                                            type="text"
                                            className={formik.touched.fatherName ? `form-control ${formik.errors.fatherName ? "invalid" : ""}` : 'form-control'}
                                            placeholder="Father Name"
                                        />
                                        {formik.errors.fatherName && formik.touched.fatherName ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.fatherName}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>



                                </div>

                                {/* Second Four Input Field */}
                                <div className='row m-1'>


                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Contact Number</label>
                                        <NumberFormat onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.contactNumber}
                                            disabled={isDisabledForStudentAdmin}
                                            name="contactNumber" placeholder="Contact Number" className={formik.touched.contactNumber ? `form-control ${formik.errors.contactNumber ? "invalid" : ""}` : 'form-control'} format="##########" />
                                        {formik.errors.contactNumber && formik.touched.contactNumber ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.contactNumber}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Father Contact</label>
                                        <NumberFormat onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.FatherContactNumber}
                                            disabled={isDisabledForStudentAdmin}
                                            name="FatherContactNumber" placeholder="Father Contact"
                                            className={formik.touched.FatherContactNumber ? `form-control ${formik.errors.FatherContactNumber ? "invalid" : ""}` : 'form-control'}
                                            format="##########" />
                                        {formik.errors.FatherContactNumber && formik.touched.FatherContactNumber ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.FatherContactNumber}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Email</label>  <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            disabled={isDisabledForStudentAdmin}
                                            name="email"
                                            type="text"
                                            className={formik.touched.email ? `form-control ${formik.errors.email ? "invalid" : ""}` : 'form-control'}
                                            placeholder="Email"
                                        />
                                        {formik.errors.email && formik.touched.email ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.email}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                </div>


                                <div className='row m-1'>
                                    <div className="col">
                                        <label className='addStdLable' htmlFor="">Gender</label>
                                        <select name="gender" value={formik.values.gender} onChange={formik.handleChange}
                                            disabled={isDisabledForStudentAdmin}
                                            onBlur={formik.handleBlur} className={formik.touched.gender ? `form-select ${formik.errors.gender ? "invalid" : ""}` : 'form-select'} id="inputGroupSelect02" placeholder="select">
                                            <option value='male'>male</option>
                                            <option value='female'>female</option>
                                            <option value='other'>other</option>


                                        </select>
                                        {formik.errors.gender && formik.touched.gender ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.gender}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">DOB</label>  <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={isDisabledForStudentAdmin}
                                            value={formik.values.dob}
                                            name="dob"
                                            type="date"
                                            className={formik.touched.dob ? `form-control ${formik.errors.dob ? "invalid" : ""}` : 'form-control'}
                                            placeholder="DOB"
                                        />
                                        {formik.errors.dob && formik.touched.dob ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.dob}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>


                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Aadhar Number</label>
                                        <NumberFormat onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.aadharNumber}
                                            disabled={isDisabledForStudentAdmin}
                                            name="aadharNumber"
                                            // placeholder="Aadhar Number"
                                            className={formik.touched.aadharNumber ? `form-control ${formik.errors.aadharNumber ? "invalid" : ""}` : 'form-control'}
                                            format="#### #### ####"
                                            mask={'X'}
                                            placeholder="EX:- 436175370721"
                                        />
                                        {formik.errors.aadharNumber && formik.touched.aadharNumber ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.aadharNumber}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                </div>
                                <div className='row m-1'>
                                    <div className="col">
                                        <label className='addStdLable' htmlFor="">Category</label>
                                        <select name="category" value={formik.values.category} onChange={formik.handleChange} disabled={isDisabledForStudentAdmin}
                                            onBlur={formik.handleBlur} className={formik.touched.category ? `form-select ${formik.errors.category ? "invalid" : ""}` : 'form-select'} id="inputGroupSelect02" placeholder="select">
                                            <option value='OBC'>OBC</option>
                                            <option value='GEN'>GEN</option>
                                            <option value='ST'>ST</option>
                                            <option value='SC'>SC</option>

                                        </select>
                                        {formik.errors.category && formik.touched.category ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.category}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Village</label>

                                        <Select
                                            options={villageNames}
                                            onChange={({ value }) => { formik.setFieldValue('village', value) }}
                                            onBlur={formik.handleBlur} isDisabled={isDisabledForStudentAdmin}
                                            name="village"
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 35,
                                                    minHeight: 40
                                                })
                                            }}
                                            className={formik.touched.village ? ` ${formik.errors.village ? "invalid" : ""}` : ''}
                                            defaultValue={StudentProfileData ? { label: StudentProfileData.accountInfo.village, value: StudentProfileData.accountInfo.village } : ''}
                                            placeholder="Village"
                                        />
                                        {formik.errors.village && formik.touched.village ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.village}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Track Name</label>

                                        <Select
                                            options={trackName} isDisabled={isDisabledForStudentAdmin}
                                            onChange={({ value }) => { formik.setFieldValue('trackName', value) }}
                                            onBlur={formik.handleBlur}
                                            name="trackName"
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 35,
                                                    minHeight: 40
                                                })
                                            }}
                                            className={formik.touched.trackName ? ` ${formik.errors.trackName ? "invalid" : ""}` : ''}
                                            defaultValue={StudentProfileData ? { label: StudentProfileData.accountInfo.trackName, value: StudentProfileData.accountInfo.trackName } : ''}
                                            placeholder="trackName"
                                        />
                                        {formik.errors.trackName && formik.touched.trackName ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.trackName}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>


                                </div>
                                {/* Fourth four input feild */}
                                <div className='row m-1'>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Enrollment number</label>  <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} disabled={isDisabledForStudentAdmin}
                                            value={formik.values.EnrollmentNumber}
                                            name="EnrollmentNumber"
                                            type="text"
                                            className={formik.touched.EnrollmentNumber ? `form-control ${formik.errors.EnrollmentNumber ? "invalid" : ""}` : 'form-control'}
                                            placeholder="Enrollment number"
                                        />
                                        {formik.errors.EnrollmentNumber && formik.touched.EnrollmentNumber ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.EnrollmentNumber}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">Stream Name</label>

                                        <select name="streamName" value={formik.values.streamName} onBlur={formik.handleBlur}
                                            disabled={isDisabledForStudentAdmin}
                                            onChange={formik.handleChange} className={formik.touched.streamName ? `form-select ${formik.errors.streamName ? "invalid" : ""}` : 'form-select'} id="inputGroupSelect02" placeholder="select">
                                            {branchNames.map((ele, i) => {
                                                return (
                                                    <option key={i} value={ele.subjects}>{ele.subjects}</option>
                                                )
                                            })}

                                        </select>
                                        {formik.errors.streamName && formik.touched.streamName ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.streamName}
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="inputGroupSelect03">Level</label>

                                        <select name="ssismLevel" value={formik.values.ssismLevel} onBlur={formik.handleBlur}

                                            onChange={formik.handleChange} className={formik.touched.ssismLevel ? `form-select ${formik.errors.ssismLevel ? "invalid" : ""}` : 'form-select'} id="inputGroupSelect0" placeholder="select">
                                            {levelsName.map((ele,i) => {
                                                return (
                                                    <optgroup className=' fw-medium' key={i} label={ele.label}>
                                                        {ele.options.map((opt,index) => {
                                                            return <option key={index} value={opt.value}>{opt.name}</option>
                                                        })}
                                                    </optgroup>
                                                )
                                            })}

                                        </select>
                                        {formik.errors.ssismLevel && formik.touched.ssismLevel ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.ssismLevel}
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                    {/* <div className="col">
                                        <label className='addStdLable' htmlFor="">Account Status</label>
                                        <select name="isActive" value={formik.values.isActive} onChange={formik.handleChange} 
                                            onBlur={formik.handleBlur} className={formik.touched.isActive ? `form-select ${formik.errors.isActive ? "invalid" : ""}` : 'form-select'} id="inputGroupSelect02" placeholder="select">
                                            <option value='false'>Deactive</option>
                                            <option value="true">Active</option>



                                        </select>
                                        {formik.errors.isActive && formik.touched.isActive ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.isActive}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div> */}


                                </div>

                                <div className='row m-1'>
                                   <div  className='col'>
                                    <div className='col d-flex align-items-baseline'>
                                        <input type='radio'
                                            // checked={true}
                                            defaultChecked={StudentProfileData ? StudentProfileData.accountInfo.ssismGroup === "ITEG" ? true : false : false}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="group"
                                            value="ITEG"
                                            className='m-2'
                                        />{' '}ITEG
                                        <input type='radio'
                                            defaultChecked={StudentProfileData ? StudentProfileData.accountInfo.ssismGroup === "MEG" ? true : false : false}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="group"
                                            value="MEG"
                                            className='m-2'
                                        />{' '}MEG{'  '}
                                        <input type='radio'
                                            defaultChecked={StudentProfileData ? StudentProfileData.accountInfo.ssismGroup === "BEG" ? true : false : false}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="group"
                                            value="BEG"
                                            className='m-2'
                                        />{' '}BEG{'  '}
                                        <input type='radio'
                                            defaultChecked={StudentProfileData ? StudentProfileData.accountInfo.ssismGroup === "other" ? true : false : false}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="group"
                                            value="other"
                                            className='m-2'
                                        />{' '}Others{'  '}
                                    </div>
                                    {formik.errors.group && formik.touched.group ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.group}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                   </div>
                                   <div  className='col'>
                                    <div className='col d-flex align-items-baseline'>
                                        <input type='checkbox'
                                            // checked={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="isPlaced"
                                            value={formik.values.isPlaced}
                                            checked={formik.values.isPlaced}
                                            className='m-2'
                                        />{' '}Is Placed
                                       
                                    </div>
                                    {formik.errors.isPlaced && formik.touched.isPlaced ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.isPlaced}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                   </div>
                                   <div  className='col'>
                                    <div className='col d-flex align-items-baseline'>
                                        <input type='checkbox'
                                            // checked={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="isPermissioned"
                                            value={formik.values.isPermissioned}
                                            checked={formik.values.isPermissioned}
                                            className='m-2'
                                        />{' '}Is Permissioned
                                       
                                    </div>
                                    {formik.errors.isPermissioned && formik.touched.isPermissioned ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.isPermissioned}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                   </div>
                                </div>
                                <div className="d-flex justify-content-end mt-3 m-1">
                                    <div className='me-3'>
                                        <button style={{ width: '116px', height: "41px" }} type='button' onClick={handleClose} className='btn  btn-light m-2'>Cancel</button>
                                        <button style={{ width: '153px', height: '41px' }} type="submit" onSubmit={formik.handleSubmit} className='btn  btn-primary ml-2' >Update</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        accountAction: (config, navigate, is_reciptBtn, setLoading) => dispatch(accountAction(config, navigate, is_reciptBtn, setLoading)),
    };
};

export default connect(null, mapDispatchToProps)(UpdateStudentPersonalInfo);
