/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import DateRangePicker from "rsuite/DateRangePicker";
import { isAccountAdmin } from "../../../helpers/AccountAdmin";
import { isStudentAdmin } from "../../../helpers/StudentAdmin";
import { isSuperAdmin } from "../../../helpers/SuperAdmin";
import allUrls from "../../../redux/constants/url";
import dateIcon from "../../assests/image/AccountIcons/DateIcon.svg";
import green from "../../assests/image/dashboard/green.svg";
import personIcon from "../../assests/image/dashboard/personIcon.svg";
import red from "../../assests/image/dashboard/red.svg";
import rupee from "../../assests/image/dashboard/rupee.svg";
import yello from "../../assests/image/dashboard/yello.svg";
import eyeIcon from "../../assests/image/eyeIcon.svg";
import BankDepositAddPopup from "../BankDepositAddPopup";
import "../styles/dashboard.css";
import BuspassBarChart from "./BuspassBarChart";
import BuspassLineChart from "./BuspassLineChart";
import InstallmentBarChart from "./InstallmentBarChart";
import MainLineChart from "./MainLineChart";
import AttedanceGuage from "./atteandanceGuage";
import AttedancePie from "./atteandancePie";

function Dashboard() {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        var con = {
          method: "GET",
          url: allUrls.dashboardRevenue,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
        const dashboardRevenue = await axios(con);
        if (dashboardRevenue.status === 200) {
          setAllRevenueData(dashboardRevenue.data);
          setLineData(dashboardRevenue.data.data2);
        }

        var configBuspass = {
          method: "GET",
          url: allUrls.getBuspassRevenue,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };

        const busPassRevenue = await axios(configBuspass);
        if (busPassRevenue.status === 200) {
          setAllBussPassData(busPassRevenue.data?.busFeesData);
          setBusPassLineData(busPassRevenue.data?.data);
        }

        var con2 = {
          method: "GET",
          url: allUrls.getSessionList,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
        const sessionList = await axios(con2);

        if (sessionList.status === 200) {
          const batchYearList = sessionList?.data?.map((item) => ({
            sessionId: item.sessionId,
            sessionName: item.sessionName,
            sessionStatus: item.sessionStatus,
          }));
          setBatchYearList(sessionList?.data);
          if (batchYearList && batchYearList.length > 0) {
            setSelectedBatch({
              sessionId: batchYearList[0]?.sessionId,
              sessionStatus: batchYearList[0]?.sessionStatus,
            });
          }
        }
      } catch (error) {
        console.log("error to fetch dashboard revenue : ", error);
      }
    };

    fetchData();
  }, []);

  const [allRevenueData, setAllRevenueData] = useState();
  const [allBussPassData, setAllBussPassData] = useState();
  const [revenue, setRevenue] = useState({
    lateFees: 0,
    receivedAmount: 0,
    remainAmount: 0,
    totalFees: 0,
    totalStudent: 0,
    waiveOff: 0,
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonth = new Date().getMonth();
  const fullMonthName = months[currentMonth];
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [lineData, setLineData] = useState(null);
  const [instData, setInstData] = useState(null);
  const [buspassLineData, setBusPassLineData] = useState(null);
  const [buspassTrackData, setBusspassTrackData] = useState(null);
  const [batchYearList, setBatchYearList] = useState(null);
  const [monthNameList, setMonthNameList] = useState();
  const [selectedMonth, setSelectedMonth] = useState(fullMonthName);
  const [selectedBatch, setSelectedBatch] = useState(0);

  const [depositAmount, setDepositAmount] = useState({
    total_received_amount: 0,
    total_deposit_amount: 0,
    remaining_amount: 0,
  });
  localStorage.setItem("batchYear", selectedBatch?.sessionId);
  useEffect(async () => {
    if (selectedBatch) {
      const matchedRevenueData = allRevenueData.data?.find(
        (item) =>
          parseInt(item.sessionId) === parseInt(selectedBatch?.sessionId)
      );
      const matchedInstData = allRevenueData.instData?.find(
        (item) =>
          parseInt(item.sessionId) === parseInt(selectedBatch?.sessionId)
      );

      // const matchedBusspassData = allBussPassData.find(
      //   (item) =>
      //     parseInt(item.sessionId) === parseInt(selectedBatch?.sessionId)
      // );

      if (matchedRevenueData && matchedRevenueData) {
        setRevenue({
          lateFees: matchedRevenueData.lateFees,
          receivedAmount: matchedRevenueData.receivedAmount,
          remainAmount: matchedRevenueData.remainAmount,
          totalFees: matchedRevenueData.totalFees,
          totalStudent: matchedRevenueData.totalStudent,
          waiveOff: matchedRevenueData.waiveOff,
        });
        setInstData(matchedInstData.sessionWiseInsData); //sessionWiseInsData
        // setBusspassTrackData(matchedBusspassData.sessionWiseBusFeesData); //sessionWiseBusFeesData
      }
      var consfigMonth = {
        method: "get",
        url:
          allUrls.getChargebleMonth + `?sessionId=${selectedBatch?.sessionId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      };
      var monthName = await axios(consfigMonth);
      if (monthName.status === 200) {
        setMonthNameList(monthName.data);
      } else {
        setMonthNameList([]);
      }
    }
  }, [selectedBatch]);

  useEffect(() => {
    if (selectedMonth !== "All") {
      if (selectedBatch?.sessionId) {
        const getMothwiseBuspass = async (month) => {
          var consfigMonth = {
            method: "get",
            url: `${allUrls.getBuspassRevenue}/${selectedBatch?.sessionId}/${selectedMonth}`,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          };
          var monthData = await axios(consfigMonth);
          if (monthData.status === 200) {
            setBusspassTrackData(monthData?.data);
          } else {
            if (selectedBatch) {
              const matchedBusspassData = allBussPassData.find(
                (item) =>
                  parseInt(item.sessionId) ===
                  parseInt(selectedBatch?.sessionId)
              );
              setBusspassTrackData(matchedBusspassData.sessionWiseBusFeesData);
            }
          }
        };
        getMothwiseBuspass();
      }
    }
    if (selectedBatch) {
      const matchedBusspassData = allBussPassData.find(
        (item) =>
          parseInt(item.sessionId) === parseInt(selectedBatch?.sessionId)
      );
      setBusspassTrackData(matchedBusspassData.sessionWiseBusFeesData);
    }
  }, [selectedMonth, selectedBatch]);

  const [attedance, setAttendance] = useState({
    attedanceGroupList: null,
    days: null,
    totalDay: null,
  });
  const dt = new Date();
  dt.setDate(1);

  const [date, setDate] = useState({ a: dt, b: new Date() });
  const [date1, setDate1] = useState({ a: dt, b: new Date() });

  const CIcon = () => {
    return (
      <>
        <img style={{ marginLeft: "-180px" }} src={dateIcon} alt="date" />
      </>
    );
  };

  useEffect(async () => {
    const token = localStorage.getItem("token");

    var con2 = {
      method: "GET",
      url: allUrls.dashboardAttendance,
      params: {
        dateFrom: `${date.a.getFullYear()}-${
          parseInt(date.a.getMonth()) >= 9 ? "" : "0"
        }${date.a.getMonth() + 1}-${
          parseInt(date.a.getDate()) >= 9 ? "" : "0"
        }${date.a.getDate()}`,
        dateTo: `${date.b.getFullYear()}-${
          parseInt(date.b.getMonth()) >= 9 ? "" : "0"
        }${date.b.getMonth() + 1}-${
          parseInt(date.b.getDate()) >= 9 ? "" : "0"
        }${date.b.getDate()}`,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    setIsLoading(true);
    const dashboardAtt = await axios(con2);
    if (dashboardAtt.status === 200) {
      setIsLoading(false);
      setAttendance(dashboardAtt.data);
    }
  }, [date]);

  const handleSessionChange = (index) => {
    setSelectedBatch({
      sessionId: batchYearList[index]?.sessionId,
      sessionStatus: batchYearList[index]?.sessionStatus,
    });
  };
  const [feeType, setFeeType] = useState("Tution");
  useEffect(async () => {
    const token = localStorage.getItem("token");

    let dateFrom = `${date1.a.getFullYear()}-${(date1.a.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date1.a.getDate().toString().padStart(2, "0")}`;
    let dateTo = `${date1.b.getFullYear()}-${(date1.b.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date1.b.getDate().toString().padStart(2, "0")}`;
    var con3 = {
      method: "GET",
      url: `${allUrls.getReceivedDepositAmountList}${dateFrom}&${dateTo}`,
      params: {
        feeType: feeType,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    setIsLoading(true);
    const depositAmount = await axios(con3);
    if (depositAmount.status === 200) {
      setDepositAmount({
        total_received_amount: depositAmount?.data[0]?.total_received_amount,
        total_deposit_amount: depositAmount?.data[0]?.total_deposit_amount,
        remaining_amount: depositAmount?.data[0]?.remaining_amount,
      });
    }
  }, [date1, feeType]);

  return (
    <div className="main-dashboard container-fluid">
      <div className="row g-2">
        {(isSuperAdmin() || isAccountAdmin()) && (
          <>
            <div className="col-8 mt-4">
              <div className=" dashboardCard ">
                <div className="container">
                  <div className="d-flex justify-content-between">
                    <div>
                      <label htmlFor="" className="card_label_text">
                        Total Revenue
                      </label>
                    </div>
                    <div className="d-flex">
                      <form action="" style={{ width: "120px" }} className="">
                        <select
                          name="batch_Year"
                          id="batch_Year"
                          value={batchYearList?.findIndex(
                            (item) =>
                              item?.sessionId === selectedBatch?.sessionId
                          )}
                          onChange={(e) => handleSessionChange(e.target.value)}
                          className="form-select"
                          style={{
                            borderInline: "transparent",
                            borderTop: "transparent",
                            borderRadius: "0rem",
                          }}
                        >
                          {batchYearList?.map((item, index) => (
                            <option key={index} value={index}>
                              {item?.sessionName}
                            </option>
                          ))}
                        </select>
                      </form>
                      {selectedBatch && selectedBatch?.sessionStatus === 1 ? (
                        <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={<Tooltip>Active session details</Tooltip>}
                        >
                          <button
                            className="btn ml-3 d-flex align-items-center justify-content-center"
                            onClick={() => {
                              isSuperAdmin() &&
                                navigate(
                                  "/admin_dashboard/studentaccounttable"
                                );
                              isAccountAdmin() &&
                                navigate(
                                  "/account_admin_dashboard/studentaccounttable"
                                );
                            }}
                          >
                            <img src={eyeIcon} alt="..." />
                          </button>
                        </Whisper>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-2 m-0 dash_bar_div ">
                      <div className="dash_bar">
                        <div className="dash_title_main">
                          <span>{revenue.totalStudent}</span>
                          <img className="ml-1" src={personIcon} alt="..." />
                        </div>
                        <div className="dash_title">Total Students</div>
                      </div>
                    </div>
                    <div className="col-2 m-0 dash_bar_div">
                      <div className="dash_bar">
                        <div className="dash_title_main">
                          <span>
                            {parseInt(revenue.totalFees).toLocaleString(
                              "en-IN"
                            )}
                          </span>
                          <img className="ml-1" src={rupee} alt="..." />
                        </div>
                        <div className="dash_title">Total Amount</div>
                      </div>
                    </div>
                    <div className="col-2 m-0 dash_bar_div">
                      <div className="dash_bar">
                        <div className="dash_title_main">
                          <span>
                            {parseInt(revenue.receivedAmount).toLocaleString(
                              "en-IN"
                            )}
                          </span>
                          <img className="ml-1" src={green} alt="..." />
                        </div>
                        <div className="dash_title">Total Earning</div>
                      </div>
                    </div>
                    <div className="col-2 m-0 dash_bar_div">
                      <div className="dash_bar">
                        <div className="dash_title_main">
                          <span>
                            {parseInt(revenue.remainAmount).toLocaleString(
                              "en-IN"
                            )}
                          </span>
                          <img className="ml-1" src={yello} alt="..." />
                        </div>
                        <div className="dash_title">Remaining Total</div>
                      </div>
                    </div>
                    <div className="col-2 m-0 dash_bar_div">
                      <div className="dash_bar">
                        <div className="dash_title_main">
                          <span>
                            {parseInt(revenue.waiveOff).toLocaleString("en-IN")}
                          </span>
                          <img className="ml-1" src={red} alt="..." />
                        </div>
                        <div className="dash_title">Waive Off</div>
                      </div>
                    </div>
                    <div className="col-2 m-0 dash_bar_div">
                      <div className="dash_bar">
                        <div className="dash_title_main">
                          <span>
                            {parseInt(revenue.lateFees).toLocaleString("en-IN")}
                          </span>
                          <img className="ml-1" src={green} alt="..." />
                        </div>
                        <div className="dash_title">Late Fees</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 mt-4">
              <div className=" dashboardCard ">
                <div className="container">
                  <div className="d-flex justify-content-between">
                    <div>
                      <label htmlFor="" className="card_label_text me-2">
                        Bank Deposits{" "}
                      </label>
                    </div>
                    <div
                      className="dpst_amt_chrt d-flex"
                      style={{ zIndex: 1000 }}
                    >
                      <form
                        action=""
                        style={{ width: "123px" }}
                        className="me-2 "
                      >
                        <select
                          name="feeType"
                          id="feeType"
                          value={feeType}
                          onChange={(e) => setFeeType(e.target.value)}
                          className="form-select"
                          style={{
                            borderInline: "transparent",
                            borderTop: "transparent",
                            borderRadius: "0rem",
                          }}
                        >
                          <option value="Tution">Tution Fee</option>
                          <option value="Bus">Bus Fee</option>
                        </select>
                      </form>
                      <DateRangePicker
                        caretAs={CIcon}
                        placement="auto"
                        value={[date1.a, date1.b]}
                        onClean={(e) => {
                          e.preventDefault();
                        }}
                        onChange={(value) => {
                          if (!value) {
                            return;
                          }
                          var a = value[0];
                          var b = value[1];
                          setDate1({
                            a,
                            b,
                          });
                        }}
                        appearance="default"
                        className=""
                        placeholder="TO"
                        style={{ width: 205 }}
                      />

                      <div className="mt-1">
                        <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={<Tooltip>Deposit Details</Tooltip>}
                        >
                          <button
                            className="btn ml-1 d-flex align-items-center justify-content-center"
                            onClick={() => {
                              isSuperAdmin() &&
                                navigate("/admin_dashboard/bankdepositamount");
                              isAccountAdmin() &&
                                navigate(
                                  "/account_admin_dashboard/bankdepositamount"
                                );
                            }}
                          >
                            <img src={eyeIcon} alt="..." />
                          </button>
                        </Whisper>
                      </div>
                      <BankDepositAddPopup setDate1={setDate1} date1={date1} />
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-4 m-0 dash_bar_div">
                      <div className="dash_bar">
                        <div className="dash_title_main">
                          <img className="mr-1" src={rupee} alt="..." />
                          <span>
                            {" "}
                            {parseInt(
                              depositAmount.total_received_amount
                            ).toLocaleString("en-IN")}
                          </span>
                        </div>
                        <div className="dash_title">Received Amount</div>
                      </div>
                    </div>
                    <div className="col-4 m-0 dash_bar_div ">
                      <div className="dash_bar">
                        <div className="dash_title_main">
                          <img className="mr-1" src={rupee} alt="..." />
                          <span>
                            {parseInt(
                              depositAmount.total_deposit_amount
                            ).toLocaleString("en-IN")}
                          </span>
                        </div>
                        <div className="dash_title">Deposited Amount</div>
                      </div>
                    </div>
                    <div className="col-4 m-0 dash_bar_div ">
                      <div className="dash_bar">
                        <div className="dash_title_main">
                          <img className="mr-1" src={rupee} alt="..." />
                          <span>
                            {parseInt(
                              depositAmount.remaining_amount
                            ).toLocaleString("en-IN")}
                          </span>
                        </div>
                        <div className="dash_title">Remaining Amount</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8  rounded">
              <div className=" dashboardCard dashboardCardGraph ">
                <label htmlFor="" className="card_label_text">
                  Fees Revenue
                </label>
                <MainLineChart lineData={lineData} />
              </div>
            </div>
            <div className="col-4  rounded">
              <div className=" dashboardCard dashboardCardGraph ">
                <label htmlFor="" className="card_label_text">
                  Installments
                </label>
                <InstallmentBarChart instData={instData} />
              </div>
            </div>

            <div className="col-6  rounded">
              <div className=" dashboardCard dashboardCardGraph ">
                <label htmlFor="" className="card_label_text">
                  Bus Pass Revenue
                </label>
                <BuspassLineChart lineData={buspassLineData} />
              </div>
            </div>
            <div className="col-6  rounded">
              <div className=" dashboardCard dashboardCardGraph ">
                <div className="d-flex">
                  <div>
                    <label htmlFor="" className="card_label_text">
                      Bus Passes
                    </label>
                  </div>
                  <div className="ms-2">
                    <form action="" className="">
                      <select
                        name="batch_Year"
                        id="batch_Year"
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        className="form-select"
                        style={{
                          borderInline: "transparent",
                          borderTop: "transparent",
                          borderRadius: "0rem",
                        }}
                      >
                        <option value="All">All Months</option>
                        {monthNameList?.map((item, index) => (
                          <option key={index} value={item?.month}>
                            {`${item?.month} ${item?.year}`}
                          </option>
                        ))}
                      </select>
                    </form>
                  </div>
                </div>
                <BuspassBarChart instData={buspassTrackData} />
              </div>
            </div>
          </>
        )}

        {(isSuperAdmin() || isStudentAdmin()) && (
          <div className="col-12  rounded mb-4">
            <div className=" dashboardCard ">
              <label htmlFor="" className="card_label_text">
                Attendance
              </label>
              <div className="container">
                <div className="row m-0 p-2 pt-3">
                  <div className="col-12 m-0 p-0 d-flex align-items-center">
                    <DateRangePicker
                      caretAs={CIcon}
                      placement="auto"
                      value={[date.a, date.b]}
                      onClean={(e) => {
                        e.preventDefault();
                      }}
                      onChange={(value) => {
                        if (!value) {
                          return;
                        }
                        var a = value[0];
                        var b = value[1];
                        setDate({
                          a,
                          b,
                        });
                      }}
                      appearance="default"
                      className="stu-acc-table"
                      placeholder="TO"
                      style={{ width: 230 }}
                    />
                    {
                      <>
                        <div className="">
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={<Tooltip>View Attendance</Tooltip>}
                          >
                            <button
                              className="btn ml-1 d-flex align-items-center justify-content-center"
                              onClick={() => navigate("attendance")}
                            >
                              <img src={eyeIcon} alt="..." />
                            </button>
                          </Whisper>
                        </div>
                      </>
                    }
                    {isLoading && attedance.attedanceGroupList !== null && (
                      <>
                        {" "}
                        <div
                          className="ml-1 spinner-border text-warning"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  {attedance.attedanceGroupList === null ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ width: "100%", height: "140px" }}
                    >
                      <div className="lds-ripple">
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <div className="row m-0">
                      <div className="col-2 m-0 p-0 d-flex flex-column align-items-center">
                        {" "}
                        <AttedancePie
                          dayData={attedance.days}
                          totalDay={attedance.totalDay}
                        />{" "}
                        <div className="label-group">Days</div>
                      </div>
                      <div className="col-2 m-0 p-0 d-flex flex-column align-items-center ">
                        {" "}
                        <AttedanceGuage
                          divName={"attiteg"}
                          attData={attedance.attedanceGroupList}
                          group={"ITEG"}
                          color={"#147AD6"}
                        />{" "}
                        <div className="label-group">ITEG</div>{" "}
                      </div>
                      <div className="col-2 m-0 p-0 d-flex flex-column align-items-center">
                        {" "}
                        <AttedanceGuage
                          divName={"attmeg"}
                          attData={attedance.attedanceGroupList}
                          group={"MEG"}
                          color={"#EC6666"}
                        />
                        <div className="label-group">MEG</div>{" "}
                      </div>
                      <div className="col-2 m-0 p-0 d-flex flex-column align-items-center">
                        {" "}
                        <AttedanceGuage
                          divName={"attbeg"}
                          attData={attedance.attedanceGroupList}
                          group={"BEG"}
                          color={"#79D2DE"}
                        />{" "}
                        <div className="label-group">BEG</div>{" "}
                      </div>
                      <div className="col-2 m-0 p-0 d-flex flex-column align-items-center">
                        {" "}
                        <AttedanceGuage
                          divName={"attsns"}
                          attData={attedance.attedanceGroupList}
                          group={"SNS"}
                          color={"#FF6542"}
                        />{" "}
                        <div className="label-group">SNS</div>{" "}
                      </div>
                      <div className="col-2 m-0 p-0 d-flex flex-column align-items-center">
                        {" "}
                        <AttedanceGuage
                          divName={"attsvs"}
                          attData={attedance.attedanceGroupList}
                          group={"SVS"}
                          color={"#779FA1"}
                        />{" "}
                        <div className="label-group">SVS</div>{" "}
                      </div>
                      {/* <div className="col-12">    <AttedancePie divName={"attbeg"}/>  </div> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
