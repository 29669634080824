import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {PDFDocument,rgb} from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import fontFamily from '../assets/Roboto-Medium.ttf';
// import {ExcelDataReader} from './ExcelDataReader'; // Adjust the import path accordingly

import level1 from '../assets/iteg-certificate/Certificates Testing.pdf'
import level2 from '../assets/iteg-certificate/Certificates Testing.pdf'
import level3 from '../assets/iteg-certificate/Certificates Testing.pdf'
import {capitalizeFirstLetter} from '../utils/capitalizeFirstLetter';


export const DownloadCertificatePdfAsZip = async (excelData) => {
    try {
        const zip = new JSZip();
        // const excelData = await ExcelDataReader(file);

        for(const {name,level,group} of excelData) {

            console.log("name is ",name,"  level is ",level)
            if(level.toLowerCase() !== 'a' && level.toLowerCase() === "1a" && level.toLowerCase() === "1b" && level.toLowerCase() === "1c" && level.toLowerCase() === "2a" && level.toLowerCase() === "2b" && level.toLowerCase() === "2c" && level.toLowerCase() === "3a" && level.toLowerCase() === "3b" && level.toLowerCase() === "3c" && level.toLowerCase() !== 'b' && level.toLowerCase() !== 'c') {
                console.error(`Level '${level}' does not exist.`);
                continue;
            }
            if(group.toLowerCase() !== 'iteg' && group.toLowerCase() !== 'beg' && group.toLowerCase() !== 'meg') {
                console.error(`group '${group}' does not exist.`);
                continue;
            }

            // Generate PDF
            const pdfBytes = await generatePDF(capitalizeFirstLetter(name),level,group);
            if(pdfBytes) {
                zip.file(`${name.toUpperCase()}-${level.toUpperCase()}-${group.toUpperCase()}.pdf`,pdfBytes);
            } else {
                console.error(`Failed to generate PDF for ${name}-${level}-${group}.`);
            }
        }

        // Generate and download ZIP file
        const zipBlob = await zip.generateAsync({type: 'blob'});
        saveAs(zipBlob,'certificates.zip');
    } catch(error) {
        console.error('Error generating PDFs:',error);
    }
};

const generatePDF = async (name,level,group) => {
    try {

        let file;
        switch(group.toLowerCase()) {
            case 'iteg':
                if(level.toLowerCase() === "a" || level.toLowerCase() === "1a" || level.toLowerCase() === "1b" || level.toLowerCase() === "1c") {
                    file = level1;
                } else if(level.toLowerCase() === "b" || level.toLowerCase() === "2a" || level.toLowerCase() === "2b" || level.toLowerCase() === "2c") {
                    file = level2;
                } else if(level.toLowerCase() === "c" || level.toLowerCase() === "3a" || level.toLowerCase() === "3b" || level.toLowerCase() === "3c") {
                    file = level3;
                } else {
                    console.error("NO level Found with given name")
                }
                break;
            case 'beg':
                console.log("===========================Currently not any certificate of BEG============================================");
                break;
            case 'meg':
                console.log("==================Currently not any certificate of MEG============================");
                break;
            default:
                console.error("No Group Found ",group)
                return null;
        }

        const response = await fetch(file);

        if(!response.ok) {
            throw new Error(`Failed to fetch PDF file: ${response.status} ${response.statusText}`);
        }

        const existingPdfBytes = await response.arrayBuffer();

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const fontBytes = await fetch(fontFamily).then(res => res.arrayBuffer());
        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes);

        const pages = pdfDoc.getPages();
        const firstPage = pages[0];


        const textWidth = customFont.widthOfTextAtSize(name,30);

        const middleCharacterIndex = Math.floor(name.length / 2);
        const middleCharacterWidth = customFont.widthOfTextAtSize(name[middleCharacterIndex],30);
        const x = (firstPage.getSize().width - textWidth) / 2 + middleCharacterWidth / 2;
        const y = firstPage.getSize().height / 2; // y-coordinate of the text position
        console.log("X center is ",(firstPage.getSize().width / 2) + 94);
        console.log("Y position is ",y - 50);

        firstPage.drawText(capitalizeFirstLetter(name),{
            x: x + 100,
            y: y - 50,
            size: 30,
            color: rgb(0,0,0),
            font: customFont
        });
        return await pdfDoc.save();
    } catch(error) {
        console.error(`Error generating PDF for ${name}-${level}:`,error);
        return null;
    }
};

