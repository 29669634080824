
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

export const CertificateExcelDownload = () => {
    const data = [
        {name: 'John',level: '1a',group: 'ITEG'},
        {name: 'Jane',level: '2b',group: 'BEG'},
        {name: 'Doe',level: '3c',group: 'MEG'},
    ];
    const worksheet = XLSX.utils.json_to_sheet(data,{
        header: ['name','level','group'],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook,worksheet,'Sheet1');
    const excelBuffer = XLSX.write(workbook,{
        bookType: 'xlsx',
        type: 'array',
    });
    const fileName = 'certificate-excel.xlsx';
    const blob = new Blob([excelBuffer],{type: 'application/octet-stream'});
    FileSaver.saveAs(blob,fileName);
};


