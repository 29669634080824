import {saveAs} from 'file-saver';
import {PDFDocument,rgb} from 'pdf-lib';
// import cert from "../assets/javascript_basic certificate.pdf";
import level1 from '../../assets/iteg-certificate/Certificates Testing.pdf'
import level2 from '../../assets/iteg-certificate/Certificates Testing.pdf'
import level3 from '../../assets/iteg-certificate/Certificates Testing.pdf'
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import fontkit from '@pdf-lib/fontkit'
import fontFamily from '../../assets/Roboto-Medium.ttf'
import {useSearchParams} from 'react-router-dom';
// import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';
// import {useMemo} from 'react';

export const DownloadCertificateFromQuery = () => {
    // function useQuery() {
    //     const {search} = useLocation();
    //     return useMemo(() => new URLSearchParams(search),[search]);
    // }
    // const searchParams = useQuery();

    const [searchParams] = useSearchParams();
    // console.log("query is " + query);
    console.log("searchParams is " + searchParams);
    const generatePDF = async (name) => {
        try {
            let file;
            const level = searchParams.get('level');
            console.log("level is ",level)
            if(searchParams.get("group").toLowerCase() === 'iteg') {
                if(level.toLowerCase() === "a" || level.toLowerCase() === "1a" || level.toLowerCase() === "1b" || level.toLowerCase() === "1c") {
                    file = level1;
                } else if(level.toLowerCase() === "b" || level.toLowerCase() === "2a" || level.toLowerCase() === "2b" || level.toLowerCase() === "2c") {
                    file = level2;
                } else if(level.toLowerCase() === "c" || level.toLowerCase() === "3a" || level.toLowerCase() === "3b" || level.toLowerCase() === "3c") {
                    file = level3;
                }
            } else if(searchParams.get("group").toLowerCase() === 'beg') {
                console.log("==========No Certificates available for beg ==============");
                return;
            } else if(searchParams.get("group").toLowerCase() === 'meg') {
                console.log("==========No Certificates available for meg ==============");
                return;
            } else {
                console.log("Wrong Group Name ");
                return;
            }
            const existingPdfBytes = await fetch(file).then((res) =>
                res.arrayBuffer()
            );

            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const fontBytes = await fetch(fontFamily).then(res => res.arrayBuffer());
            pdfDoc.registerFontkit(fontkit);
            const customFont = await pdfDoc.embedFont(fontBytes);

            const pages = pdfDoc.getPages();
            const firstPage = pages[0];


            const textWidth = customFont.widthOfTextAtSize(name,30);

            const middleCharacterIndex = Math.floor(name.length / 2);
            const middleCharacterWidth = customFont.widthOfTextAtSize(name[middleCharacterIndex],30);
            const x = (firstPage.getSize().width - textWidth) / 2 + middleCharacterWidth / 2;
            const y = firstPage.getSize().height / 2; // y-coordinate of the text position
            console.log("X center is ",(firstPage.getSize().width / 2) + 94);
            console.log("Y position is ",y - 50);

            firstPage.drawText(capitalizeFirstLetter(name),{
                x: x + 100,
                y: y - 50,
                size: 30,
                color: rgb(0,0,0),
                font: customFont
            });
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes],{type: "application/pdf"});

            saveAs(blob,`${name}-${level}.pdf`);
        } catch(error) {
            console.error("Error generating PDF:",error);
        }
    };


    const submitHandler = async () => {
        if(searchParams.get("type") === 'certificate') {
            const val = capitalizeFirstLetter(searchParams.get('name')?.trim());
            console.log("value of val capitalize is",val)
            if(val !== "") {

                await generatePDF(val);
                // window.location.href = ('https://central.ssism.org');
                // window.location.href = ('/certificate-query-form');
                window.history.back();

            } else {
                console.log("not a valid data name ");
            }
        } else {
            alert("type is not certificate");
            window.history.back();
            // window.location.href = ('/certificate-query-form');
            // window.location.href = ('https://central.ssism.org');

        }
    };
    submitHandler();
    return null;
}
