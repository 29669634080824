import React, { useState } from "react";

import Edit_icon from "../../assests/image/Edit_icon.svg";
import slidersImg from "../../assests/image/sliders-solid.svg";
import externalLinkCertificate from '../../assests/image/level-certificates/external link icon certificate.png'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../styles/AddNewStudent.css";
import { useNavigate } from "react-router-dom";
import student_Profile__RocketImg from "../../assests/image/AccountIcons/studentProfileRocketImg.svg";
import { isSuperAdmin } from "../../../helpers/SuperAdmin";
import FeesDetalisActiveStudents from "./FeesDetalisActiveStudents";
// import NumberFormat from 'react-number-format'
import {
  CModal,
  CModalBody,
} from "@coreui/react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import AvatarImg from "../../assests/image/Avtar.jpeg";
import ToastContainer from "rsuite/esm/toaster/ToastContainer";import UpdateStudentPersonalInfo from "./UpdateStudentPersonalInfo";
import "./Styles/StudentAccountTable.css";
import { Tooltip, Whisper } from "rsuite";
import { Avatar } from "@mui/material";
import ActionsStudents from "./ActionsStudents";
import {isStudentAdmin} from "../../../helpers/StudentAdmin";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 800,
  bgcolor: "white",

  // p: 2,
  // px: 4,
  pb: 3,
};

function StudentProfile({ accountAction }) {


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const navigate = useNavigate();

  const data = localStorage.getItem("userEdit");
  const StudentProfileData = JSON.parse(data);
  console.log("Student Profile Data ============================= ",StudentProfileData)
  const [loaderLoading, setLoaderLoading] = useState(false)
  // // console.log(StudentProfileData);

  var StudentName =
    StudentProfileData.accountInfo.firstName +
    " " +
    StudentProfileData.accountInfo.lastName;
  var StudentClassName =
    StudentProfileData.accountInfo.branch.toUpperCase() +
    "-" +
    StudentProfileData.accountInfo.year;
  const levelName = StudentProfileData?.accountInfo?.ssismLevel ?? '1a'.toUpperCase();
  var StudentPhoto = StudentProfileData.accountInfo.photo;
  // // console.log(StudentPhoto);


  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

        <div
          className="d-flex my-3 me-3 ms-2"
          style={{ backgroundColor: "#E6E9F4", borderRadius: "8px" }}>
          <div className="col-6 d-flex my-5">
            <div>
              {
                StudentPhoto.includes("/null") ? (
                  <img
                    style={{
                      borderRadius: "50px",
                      height: "100px",
                      width: "100px",
                      marginLeft: "50px",
                      backgroundColor: "#DDDDDD",
                    }}
                    src={AvatarImg}
                    alt="avtar_photo"
                  />
                ) : (
                  <Avatar
                    src={
                      StudentPhoto.includes(
                        `PHOTO_${StudentProfileData.accountInfo.stdId}`
                      )
                        ? StudentPhoto
                        : `  ${StudentPhoto.replace(
                            "documents/",
                            "profile"
                          ).replace(StudentProfileData.accountInfo.stdId, "")}`
                    }
                    alt="profile"
                    style={{
                      borderRadius: "50px",
                      height: "100px",
                      width: "100px",
                      marginLeft: "50px",
                      backgroundColor: "#DDDDDD",
                    }}
                    className="mx-auto">
                    <span
                      style={{ fontSize: "75px", color: "rgb(90, 96, 127)" }}>
                      {StudentName.slice(0, 1)}
                    </span>
                  </Avatar>
                )
                // <img style={{borderRadius: "50px",height: "100px",width: '100px',marginLeft: '50px',backgroundColor: '#DDDDDD'}} src={StudentPhoto} alt="avtar_photo" />
              }

              {/* <img src={Edit_icon} alt='edit_icon' className='mt-5' style={{marginLeft: "-10px",height: '20px',width: '20px'}} /> */}
            </div>
            <div className="ml-4">
              <div style={{ color: "#5A607F" }}>
                <span className="fw-bold" style={{ fontSize: "22px" }}>
                  {StudentName}
                </span>
                <br />
                <span style={{ fontWeight: 500 }}>
                  {" "}
                {StudentProfileData.accountInfo.ssismGroup} {"   "}
                {/* {`${StudentClassName} (${
                    StudentProfileData.accountInfo.joinBatch +
                    "-" +
                    (parseInt(StudentProfileData.accountInfo.joinBatch) + 3)
                  })`} */}
                {"Level "}    {levelName.toUpperCase()}
                {/* {"Level-A"} {"  "} */}
              </span> {" "}
              {isStudentAdmin() || isSuperAdmin() ? <Whisper placement="right" controlId="control-id-hover" trigger="hover" speaker={
                <Tooltip>
                  Download Certificate.
                </Tooltip>
              }>
                <img src={externalLinkCertificate}
                  alt=""
                  aria-describedby="control-id-hover"
                  height={15}
                  width={15}
                  style={{color: '#5A607F',alignSelf: 'center'}}
                  onClick={() => {
                    navigate(`/download-level-certificate?name=${StudentName}&level=${levelName}&type=certificate&group=${StudentProfileData?.accountInfo?.ssismGroup}`);
                  }}
                />
              </Whisper> : null}
              </div>
            {!isStudentAdmin() ? <div
                className="btn-group mt-2"
                role="group"
                aria-label="Basic example">
                <button 
                  style={{
                    backgroundColor: "#f99300",
                    borderRadius: "4px 4px 4px 4px",
                  }}
                  onClick={() => {
                    navigate("feesrecipt");
                  }}
                  className="btn  btn-warning text-light fw-bold"
                  type="submit">
                  Receipt
                </button>
                <button
                  style={{
                    backgroundColor: "#f99300",
                    marginLeft:"4px",
                    marginRight:"5px",
                    borderRadius: "4px 4px 4px 4px"
                  }}
                  onClick={() => {
                    navigate("bus-pass");
                  }}
                  className="btn  btn-warning text-light fw-bold"
                  type="submit">
                  Bus Pass
                </button>

                <button
                  style={{
                    color: "#4F83DF",
                    backgroundColor: "#e6e9f4",
                    borderRadius: "4px 4px 4px 4px",
                    marginRight:"5px",
                    border: "1px solid #4F83DF",
                  }}
                  className="btn btn-outline fw-bold"
                  type="submit"
                  onClick={() => {
                    navigate((isSuperAdmin() ? "/admin_dashboard/" : "/account_admin_dashboard/") + "studentprofile/uploaddocument");
                  }}>
                  Upload Document
                </button>

                <button
                  style={{
                    color: "#4F83DF",
                    backgroundColor: "#e6e9f4",
                    borderRadius: "4px 4px 4px 4px",
                    border: "1px solid #4F83DF",
                  }}
                  className="btn btn-outline fw-bold"
                  type="submit"
                  onClick={() => {
                    navigate("activity");
                  }}
                  >
                  Activities
                </button>

            </div> : null}
            {/* <div className="mt-2 d-flex justify-content-between align-items-center" style={{color: "#5A607F"}} >
              <span style={{fontWeight: 500,fontSize: "20px"}}>  Level-a</span>
              <span style={{fontWeight: 500,fontSize: "20px"}}>{StudentProfileData?.accountInfo?.ssismGroup?.toUpperCase()} </span>
              <button
                style={{
                  color: "#4F83DF",
                  backgroundColor: "#e6e9f4",
                  borderRadius: "4px 4px 4px 4px",

                  border: "1px solid #4F83DF",
                }}
                className="btn btn-outline fw-bold"
                type="submit"
                onClick={() => {
                  navigate(`/download-level-certificate?name=${StudentName}&level=1a&type=certificate&group=${StudentProfileData?.accountInfo?.ssismGroup}`);
                }}
              >
                Download Certificate
              </button></div> */}
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <img
              src={student_Profile__RocketImg}
              className="mt-4 "
              alt="rocket"
            />
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Edit personal info.</Tooltip>}>
              <img
                src={Edit_icon}
                onClick={handleOpen}
                className="mb-1 "
                alt="rocket"
                style={{
                  height: "40px",
                  width: "40px",
                  alignSelf: "self-end",
                  cursor: "pointer",
                }}
              />
              {/* <img src={Edit_icon} alt='edit_icon'  /> */}
            </Whisper>
          {!isStudentAdmin() ? <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Actions.</Tooltip>}>
              <img
                src={slidersImg}
                onClick={handleOpen2}
                className="mb-2"
                alt="rocket"
                style={{
                  height: "25px",
                  width: "25px",
                  alignSelf: "self-end",
                  cursor: "pointer",
                }}
              />
              {/* <img src={Edit_icon} alt='edit_icon'  /> */}
          </Whisper> : null}
          </div>
        </div>

        <StyledModal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
          onClose={handleClose}
          BackdropComponent={Backdrop}>
          <Box sx={style}>
            <UpdateStudentPersonalInfo
              handleClose={handleClose}
              disable={false}
            />
          </Box>
        </StyledModal>
        <CModal alignment="center" visible={open2} onClose={handleClose2}>
          <CModalBody>
            <ActionsStudents
              actionType={1}
              handleClose={handleClose2}
              disable={false}
            />
          </CModalBody>
        </CModal>

      
      {!isStudentAdmin() && StudentProfileData.allAccounts.reverse().map((ele,index) => {

return  <FeesDetalisActiveStudents key={index+1} i = {index} StudentProfileData={{...StudentProfileData.accountInfo,...ele}} isDisable={!isSuperAdmin()} />
})}
     
    </div>
  );
}

export default StudentProfile;
