//main url in the application
// export const baseUrl = "https://singaji-central-server.herokuapp.com";
// export const baseUrl = "https://singaji-central-server-new-db.herokuapp.com";
// export const baseUrl = "http://localhost:5000";
import{ baseUrl } from '../../config'


export const loginUrl = "/api/login";

export const forgetPass = "/api/resetPasswordEmail";

const AllUrl = {
    // Register New student url
    newRegistraionUrl: `${baseUrl}/api/register`,

    // permission url 
    permissions: `${baseUrl}/api/permissions`,

    // Fess structure related url 
    updateSchema: `${baseUrl}/api/update_schema`,
    createSchema: `${baseUrl}/api/create_schema`,
    allSchemaList: `${baseUrl}/api/list_schema`,
    activeMultipleSchema: `${baseUrl}/api/active_schema_multiple_selections`,
    activeSchema: `${baseUrl}/api/active_schema`,
    //_____________________________________

    //Admin related url
    createNewAdmin: `${baseUrl}/api/createNewAdmin`,
    infoAllAdmin: `${baseUrl}/api/infoOfAdmins`,
    allActiveStudent: `${baseUrl}/api/infoOfAllActiveStudents`,
    showFees: `${baseUrl}/api/show_fees/`,
    adminStatusChange: `${baseUrl}/api/deactive_or_activate_admin`,
    roleList: `${baseUrl}/api/role_list`,
    //_____________________________________


    //Student related url
    allRegistratedStudent: `${baseUrl}/api/registrated_student`,
    allRegistratedStudentAccountList: `${baseUrl}/api/info_Of_All_Registered_Student_Account`,
    deleteStudent: `${baseUrl}/api/delete_cancel_student`,
    verifyStudent: `${baseUrl}/api/varify_student`,
    verifyStudentPaidUnpaid: `${baseUrl}/api/reg_fees_status`,
    verifyStudentAccountPaidUnpaid: `${baseUrl}/api/acc_reg_fees_status`,
    addNewStudent: `${baseUrl}/api/register`,
    updateRegisterStudentInfo: `${baseUrl}/api/update_registrated_students`,
    updatePersonalInformation: `${baseUrl}/api/update_personal_info`,// update the personal information of a particular student 
    updateAccountInformation: `${baseUrl}/api/update_account_info`,// update the Account information of a particular student 
    allInfoOfActiveStudent: `${baseUrl}/api/all_info_of_active_student`,// get the all data about student related
    pendingFeesReport: `${baseUrl}/api/get_scholarship_pending_list`,// pending fees report
    GetDocumentsOfStudents: `${baseUrl}/api/get_documents`, // get documents of particular student
    UploadDocument: `${baseUrl}/api/upload_document`, // get documents of particular student
    accountStudent: `${baseUrl}/api/get_students_account_list/active`,
    accountStudentDeactive: `${baseUrl}/api/get_students_account_list/deactive`,
    selfRegisterStudents: `${baseUrl}/api/self_registrated_student`,
    registeredStudentsByTrackName: `${baseUrl}/api/registered_student`,
    shiftToAppliedStudent: `${baseUrl}/api/shift_to_applied_students`,
    deleteSelfAppliedStudents: `${baseUrl}/api/delete_self_applied_students`,
    notificationStudent: `${baseUrl}/api/update_setting/FEES_WHATSHAPP_NOTIFICATION`,
    getNotification: `${baseUrl}/api/get_setting/FEES_WHATSHAPP_NOTIFICATION`, // Get initial notification data
    getSessionList: `${baseUrl}/api/session_list`, // Get All session list

    //_____________________________________

    //landing pages urls 
    login: `${baseUrl}/api/login`,
    registerNewStudent: `${baseUrl}/api/register`,
    forgotPassword: `${baseUrl}/api/resetPasswordLink/`, //new password
    resetPassword: `${baseUrl}/api/resetPasswordEmail`, //only email send
    selfRegistration: `${baseUrl}/api/self_registration`,
    selfRegistrationShort: `${baseUrl}/api/self_registration_short`,
    //_____________________________________

    // helpers related url
    receiptGenerate: `${baseUrl}/api/generatingFeesRashid`,
    trackList: `${baseUrl}/api/track_list`,
    branchList: `${baseUrl}/api/list_branch`,
    schoolNameList: `${baseUrl}/api/school_list`,
    villageNameList: `${baseUrl}/api/village_name`,
    villageNameById: `${baseUrl}/api//track_name/3`, // where 3 is an id of track
    uploadFile: `${baseUrl}/api/uploadFile`,
    reportReceipt: `${baseUrl}/api/list_of_reported_receipt`,
    deleteReportedList: `${baseUrl}/api/delete_reported_receipt`,
    rejectReceipt: `${baseUrl}/api/report_receipt`,//to deactivate a student fees receipt reject

    //_____________________________________

    //Account admin tables url 

    dailyReport: `${baseUrl}/api/daily_account_report/`,// these are date
    // pendingScholarship: `${baseUrl}/api/get_scholarship_pending_list`,
    pendingScholarship: `${baseUrl}/api/get_pending_fees_detail_list`, // new url for all session and all pending installment+scholarship data
    feesReceiptTable: `${baseUrl}/api/fees_receipt_list/1&100`,// 3&2 are dynamic
    generateReciept: `${baseUrl}/api/generate_reciept`,

    // Fees Receipt delete api

    deletereciept: `${baseUrl}/api/report_receipt`,
    updatereciept: `${baseUrl}/api/update_receipt`,

    // personal profile settings 

    settingApi: `${baseUrl}/api/edit_profile`,

    // attedance 
    getAttendace: `${baseUrl}/api/get_attendance`,
    getHolidays: `${baseUrl}/api/get_holidays`,
    addHoliday: `${baseUrl}/api/add_holiday`,
    deleteHoliday: `${baseUrl}/api/delete_holiday`,
    getEmployeesAttandance: `${baseUrl}/api/get_employee_attendance`,
    getAttendanceOfStuentById: `${baseUrl}/api/get_student_attenddance_present_or_leave`,
    addLeaveOrAttendance: `${baseUrl}/api/add_student_present_or_leave`,

    // dashboard
    dashboardRevenue: `${baseUrl}/api/dashboard_student_revenue`,
    dashboardAttendance: `${baseUrl}/api/dashboard_student_attendance`,
    registrationsDashboard: `${baseUrl}/api/dashboard_student_registrations`,
    // dashboardPendingFees: `${baseUrl}/api/get_pending_fees_detail_list/4/GKB`,

    aluminiAccList: `${baseUrl}/api/get_students_alumni_deactive_account_list/active`,
    deActiveAccList: `${baseUrl}/api/get_students_alumni_deactive_account_list/deactive`,

    // action on students
    actionOnStudents: `${baseUrl}/api/action_student`,
    promoteStudents: `${baseUrl}/api/shift_students`,

    // bus pass
    getChargebleMonth: `${baseUrl}/api/bus_pass/get_chargable_month`,
    generateBusPass: `${baseUrl}/api/bus_pass/generate`,
    addChargeableMonth: `${baseUrl}/api/bus_pass/add_chargable_month`,
    sendMessage: `${baseUrl}/api/send_message`,
    getMessagesTemplates: `${baseUrl}/api/messages_template`,
    getBuspassRevenue: `${baseUrl}/api/get_Bus_fees_details`,
    getTrackwiseBusDetails: `${baseUrl}/api/get_Pending_Bus_fees_details`,

    // Student Activities
    generateActivity: `${baseUrl}/api/student_report`,
    updateActivity: `${baseUrl}/api/update_student_report`,
    getAllActivities: `${baseUrl}/api/get_all_reports`,
    getSingleReportById: `${baseUrl}/api/get_student_activity_attachments`,
    deleteOneAttachment: `${baseUrl}/api/delete_student_activity_attachment`,
    getNotificationCount: `${baseUrl}/api/assigned_report_count`,
    getdepositAmountList: `${baseUrl}/api/deposit_received_amount_list/`,
    getReceivedDepositAmountList: `${baseUrl}/api/deposit_received_amount_defference_detail/`,
    getBankNames: `${baseUrl}/api/bank_detail_list`,
    depositAmount: `${baseUrl}/api/deposit_amount`
}
export default AllUrl
